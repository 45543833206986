<template>
  <div class="users">
    <form class="users__form" @submit.prevent="onSubmit">
      <base-form-input class="form__input" label="Email or id">
        <input v-model="input" :placeholder="'john@doe.com'" />
      </base-form-input>
      <base-button type="submit">Search</base-button>
    </form>
    <p v-if="loading">Loading...</p>
    <p v-if="notFound">User not found</p>
    <transition-group name="list" mode="out-in" class="users__list" tag="ul">
      <app-user-cell v-if="user" :key="user.id" :user="user" class="list__user" @refund="onRefund" />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { UserFragments } from '@/apollo/fragments/user';
import AppUserCell from '@/components/UserCell.vue';
import {
  Deposit,
  ITransaction,
  MeetAndGreetTicketPurchase,
  Order,
  RefundMeetAndGreetTicketInput,
  Ticket,
  User,
} from '@/types/api';
import gql from 'graphql-tag';
import { SmartQuery } from 'vue-apollo/types/vue-apollo';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isDeposit, isMeetAndGreetTicketPurchase, isOrder, isTicket } from '../utils/assessInterfaceType';

@Component({
  components: { AppUserCell },
})
export default class Users extends Vue {
  input = '';
  user: User | null = null;
  loading = false;
  notFound = false;
  suggestedCreditRefund = 0;
  private smartQuery: SmartQuery<User> | null = null;

  public get searchQuery() {
    return (this.$route.query.q as string) || '';
  }

  private get isInputEmail() {
    return this.input.includes('@');
  }

  private get variables() {
    return this.isInputEmail ? { email: this.input } : { id: this.input };
  }

  @Watch('searchQuery', { immediate: true })
  public onSearchQueryChange() {
    this.input = this.searchQuery;
    if (this.input) this.search();
  }

  public beforeDestroy() {
    this.stopSmartQuery();
  }

  onSubmit() {
    this.$router.push({ query: { q: this.input.trim() } }).catch(() => {});
  }

  onRefund(transaction: ITransaction) {
    if (isDeposit(transaction)) this.refundDeposit(transaction);
    else if (isTicket(transaction)) this.refundTicket(transaction);
    else if (isOrder(transaction)) this.refundOrder(transaction);
    else if (isMeetAndGreetTicketPurchase(transaction)) this.refundMeetAndGreetTicketPurchase(transaction);
  }

  private search() {
    this.stopSmartQuery();
    this.user = null;
    this.notFound = false;
    this.suggestedCreditRefund = 0;
    try {
      this.loading = true;
      this.smartQuery = this.$apollo.addSmartQuery<{ user: User }>('user', {
        query: gql`
          query User($id: ID, $email: String) {
            user(id: $id, email: $email) {
              ...User
            }
          }
          ${UserFragments.User}
        `,
        variables: this.variables,
        fetchPolicy: 'network-only',
      });
    } catch {
      this.notFound = true;
    } finally {
      this.loading = false;
    }
  }

  private async refundDeposit(deposit: Deposit) {
    const maxRefund = Math.min(deposit.creditsRefundable, this.user?.wallet.balance || 0);
    const input = await this.$modal.prompt({
      title: 'Refund deposit',
      message: `Credits (max ${maxRefund}) that will be withdrawn from user balance. A successful operation should create a stripe refund. User will also get an email notification.`,
      prompt: {
        label: 'Credits',
        placeholder: `${this.suggestedCreditRefund}`,
      },
    });
    if (!input) return;
    const credits = parseInt(input, 10);
    if (isNaN(credits)) return;
    await this.$apollo.mutate({
      mutation: gql`
        mutation CreateRefund($input: CreateRefundInput!) {
          createRefund(input: $input) {
            refund {
              id
            }
          }
        }
      `,
      variables: { input: { depositId: deposit.id, credits } },
    });
    this.suggestedCreditRefund = 0;
    this.smartQuery?.refresh();
  }

  private async refundTicket(ticket: Ticket) {
    const confirm = await this.$modal.confirm({
      title: 'Refund ticket',
      message: 'User will gain credits back. User will also get an email notification. Continue?',
    });
    if (!confirm) return;
    await this.$apollo.mutate({
      mutation: gql`
        mutation RefundTicket($input: RefundTicketInput!) {
          refundTicket(input: $input) {
            ticket {
              id
              refundedAt
            }
          }
        }
      `,
      variables: { input: { id: ticket.id } },
    });
    this.suggestedCreditRefund += ticket.credits;
    this.smartQuery?.refresh();
  }

  private async refundMeetAndGreetTicketPurchase(purchase: MeetAndGreetTicketPurchase) {
    const confirm = await this.$modal.prompt({
      title: 'Refund Secret Gathering',
      message: 'User will gain credits back. User will also get an email notification. Continue?',
      prompt: {
        label: 'How many?',
        placeholder: `${purchase.quantity}`,
      },
    });
    if (!confirm) return;
    const quantity = parseInt(confirm, 10);
    if (!quantity || quantity > purchase.quantity || quantity < 1) {
      this.$modal.alert({ title: 'Invalid quantity', message: 'Please enter a valid quantity' });
      return;
    }
    await this.$apollo.mutate({
      mutation: gql`
        mutation RefundMeetAndGreetTicket($input: RefundMeetAndGreetTicketInput!) {
          refundMeetAndGreetTicket(input: $input) {
            purchase {
              id
              refundedAt
            }
          }
        }
      `,
      variables: { input: { id: purchase.id, quantity } satisfies RefundMeetAndGreetTicketInput },
    });
    this.suggestedCreditRefund += purchase.credits;
    this.smartQuery?.refresh();
  }

  private async refundOrder(order: Order) {
    const confirm = await this.$modal.confirm({
      title: 'Refund order',
      message:
        'User will gain credits back. User will also get an email notification. Make sure to cancel the shopify order! Continue?',
    });
    if (!confirm) return;
    await this.$apollo.mutate({
      mutation: gql`
        mutation RefundOrder($input: RefundOrderInput!) {
          refundOrder(input: $input) {
            order {
              id
              refundedAt
            }
          }
        }
      `,
      variables: { input: { id: order.id } },
    });
    this.suggestedCreditRefund += order.credits;
    this.smartQuery?.refresh();
  }

  private stopSmartQuery() {
    this.smartQuery?.stop();
    this.smartQuery = null;
  }
}
</script>

<style lang="scss" scoped>
.users {
}

.users__form {
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;

  .form__input {
    flex-grow: 1;
    max-width: 400px;
    margin-right: 8px;
  }
}

.users__list {
}

.list__user {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
