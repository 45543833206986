import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { MeetAndGreetProduct } from '@/types/api';
import gql from 'graphql-tag';
import { MeetAndGreetProductForm } from '.';
import { PurchasableFragments } from '../../apollo/fragments/purchasable';
import { MeetAndGreetVariantForm } from './variant/MeetAndGreetVariantForm';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateMeetAndGreetProduct($input: CreateMeetAndGreetProductInput!) {
        createMeetAndGreetProduct(input: $input) {
          product {
            ...Purchasable
          }
        }
      }
      ${PurchasableFragments.Purchasable}
    `,
    refetchQueries: ['MeetAndGreetProducts'],
  },
})
export class CreateMeetAndGreetProductForm extends GraphQLForm<MeetAndGreetProduct> implements MeetAndGreetProductForm {
  @Field()
  public title = '';

  @Field()
  public description = '';

  @Field()
  public price?: number;

  @Field()
  public image: File | null = null;

  @Field()
  public visibleForPurchase = true;

  @Field()
  public visibleForRandom = true;

  @Field()
  public visibleForLoyal = true;

  @Field()
  public variantInputs = [new MeetAndGreetVariantForm()];

  public save() {
    return super.save({
      refetchQueries: ['MeetAndGreetProducts'],
      // optimisticResponse: {
      //   CreateMeetAndGreetProduct: {
      //     product: {
      //       id: 'optimistic-id',
      //       title: this.title,
      //       description: this.description,
      //       price: this.price,
      //       isCitizenRestricted: this.isCitizenRestricted,
      //       requiredScore: this.requiredScore,
      //       imageUrl: '',
      //       variants: this.variantInputs.map((a) => ({
      //         id: a.id,
      //         name: a.name,
      //         __typename: 'Variant',
      //       })),
      //       image: null,
      //       __typename: 'Product',
      //     },
      //     __typename: 'CreateMeetAndGreetProductPayload',
      //   },
      //   __typename: 'Mutation',
      // },
    });
  }
}
