import gql from 'graphql-tag';
import { VideoFragments } from './video';

export namespace MovieFragments {
  export const Movie = gql`
    fragment Movie on Movie {
      id
      title
      body
      imageUrl
      sortOrder
      publishedAt
      video {
        ...Video
      }
    }
    ${VideoFragments.Video}
  `;
}
