<template>
  <vue-editor
    class="html-editor"
    :value="value"
    @input="(event) => $emit('input', event)"
    :editor-toolbar="customToolbar"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';

@Component({
  components: {
    VueEditor,
  },
})
export default class HtmlEditor extends Vue {
  @Prop({ default: '' })
  value!: string;

  customToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: ['#e79517', '#b62c1a'] }], // dropdown with defaults from theme
    [
      {
        font: ['Franklin Gothic Medium', 'Arial Narrow', 'Arial', 'sans-serif'],
      },
    ],
    [{ align: [] }],

    ['clean', 'link'], // remove formatting button
  ];
}
</script>

<style lang="scss">
.html-editor {
  display: contents;

  .ql-snow {
    border-color: $color-theme-yellow !important;
  }
}
</style>
