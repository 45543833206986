<template>
  <li class="cell" :class="{ focused }">
    <div class="cell__content">
      <slot />
      <slot name="content" v-if="!edit" />
      <slot name="form" v-else />
    </div>
    <div class="cell__actions">
      <slot name="actions" v-if="$slots.actions" />
      <template v-else>
        <base-button v-if="edit" key="save" :disabled="loading" @click="$emit('save')">Save</base-button>
        <base-button v-if="edit" key="cancel" :disabled="loading" @click="$emit('cancel')">Cancel</base-button>
        <base-button v-if="edit && deletable" key="delete" @click="onDelete()" :disabled="loading" :danger="true"
          >Delete</base-button
        >
        <base-button v-if="!edit && editable" key="edit" @click="$emit('edit')">Edit</base-button>
      </template>
    </div>
  </li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Cell extends Vue {
  @Prop({
    default: false,
  })
  edit!: boolean;

  @Prop({
    default: true,
  })
  deletable!: boolean;

  @Prop({
    default: true,
  })
  editable!: boolean;

  @Prop({
    default: false,
  })
  focused!: boolean;

  @Prop({
    default: true,
  })
  loading!: boolean;

  async onDelete() {
    const confirm = await this.$modal.confirm({
      title: 'Confirm deletion',
      message: 'Are you sure you want to delete this entry?',
    });
    if (confirm) {
      this.$emit('delete');
    }
  }
}
</script>

<style lang="scss" scoped>
.cell {
  padding: 16px;
  border: 1px solid $color-theme-red;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    button {
      min-width: 80px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &-move {
      transition: all 0.2s;
    }
  }
  &.focused {
    outline: 2px solid $color-theme-yellow;
  }
}
</style>
