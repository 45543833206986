import GlobalVue from 'vue';

export enum ModalType {
  Alert = 'alert',
  Confirm = 'confirm',
  Prompt = 'prompt',
}

export interface ModalData {
  title: string;
  message: string;
}

export interface PromptModalData extends ModalData {
  prompt: {
    label: string;
    placeholder: string;
  };
}

export class ModalService {
  public eventBus = new GlobalVue();

  private cb: ((value: any) => void) | null = null;

  public confirm(data: ModalData): Promise<boolean> {
    return new Promise((resolve) => {
      this.eventBus.$emit('open', ModalType.Confirm, data);
      this.cb = resolve;
    });
  }

  public prompt(data: PromptModalData): Promise<string> {
    return new Promise((resolve) => {
      this.eventBus.$emit('open', ModalType.Prompt, data);
      this.cb = resolve;
    });
  }

  public alert(data: ModalData): void {
    this.eventBus.$emit('open', ModalType.Alert, data);
  }

  public close(affirmative: boolean) {
    this.eventBus.$emit('close');
    this.cb?.(affirmative);
    this.cb = null;
  }
}

export const globalModalService = new ModalService();

export const ModalPlugin = {
  install(Vue: typeof GlobalVue) {
    Vue.set(Vue.prototype, '$modal', globalModalService);
  },
};
