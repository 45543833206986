<template>
  <div class="chat">
    <template v-if="chat">
      <form class="form" @submit.prevent="onCommentSubmit">
        <base-form-input class="form__input">
          <input
            type="text"
            v-model="commentDraft"
            :minLength="2"
            :maxLength="200"
            placeholder="Enter your comment (2-200 chars)"
          />
        </base-form-input>
        <base-button type="submit" class="form__button">
          {{ 'Send' }}
        </base-button>
      </form>
      <ul class="comments">
        <li
          v-for="comment in chat.comments.data"
          :key="comment.id"
          class="comment"
          :class="{ admin: comment.userProfile?.role === 'Admin' }"
        >
          <span class="comment__date">{{ comment.createdAt | dayjs('HH:mm:ss') }}</span>
          <span class="comment__author">{{ ` [${comment.userProfile?.username ?? 'DELETED'}]` }}</span>
          <span>{{ ': ' }}</span>
          <span class="comment__body">{{ comment.body }}</span>
          <div class="comment__actions">
            <button @click="onCommentDelete(comment)">Delete</button>
          </div>
        </li>
        <base-button @click="fetchMoreComments" v-if="hasMoreComments">More...</base-button>
      </ul>
    </template>
  </div>
</template>

<script lang="ts">
import { ChatFragments } from '@/apollo/fragments/chat';
import { Chat, ChatComment } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  apollo: {
    chat: {
      query: gql`
        query Chat($id: ID!, $pagination: CommentsPageInput) {
          chat(id: $id) {
            ...ChatWithComments
          }
        }
        ${ChatFragments.ChatWithComments}
      `,
      variables() {
        return {
          id: this.chatId,
          pagination: {
            take: 50,
          },
        };
      },
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document: gql`
            subscription CommentAdded($id: ID!) {
              commentAdded(id: $id) {
                comment {
                  ...Comment
                }
              }
            }
            ${ChatFragments.Comment}
          `,
          variables() {
            return {
              id: (this as any).chatId,
            };
          },
          updateQuery: (previousResult: { chat: Chat }, { subscriptionData }) => {
            const comment = subscriptionData.data.commentAdded.comment;
            return {
              chat: {
                ...previousResult.chat,
                comments: {
                  ...previousResult.chat.comments,
                  data: [comment, ...previousResult.chat.comments.data],
                },
              },
            };
          },
        },
        {
          document: gql`
            subscription CommentDeleted($id: ID!) {
              commentDeleted(id: $id) {
                id
              }
            }
          `,
          variables() {
            return {
              id: (this as any).chatId,
            };
          },
          updateQuery: (previousResult: { chat: Chat }, { subscriptionData }) => {
            const deletedCommentId = subscriptionData.data.commentDeleted.id;
            return {
              chat: {
                ...previousResult.chat,
                comments: {
                  ...previousResult.chat.comments,
                  data: previousResult.chat.comments.data.filter((c) => c.id !== deletedCommentId),
                },
              },
            };
          },
        },
      ],
    },
  },
})
export default class ChatView extends Vue {
  @Prop() chatId!: string;

  chat?: Chat;

  commentDraft = '';

  get hasMoreComments(): boolean {
    return this.chat?.comments.hasMore || false;
  }

  async onCommentSubmit() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation CreateChatComment($input: CreateChatCommentInput!) {
          createChatComment(input: $input) {
            comment {
              ...Comment
            }
          }
        }
        ${ChatFragments.Comment}
      `,
      variables: {
        input: {
          chatId: this.chatId,
          body: this.commentDraft,
        },
      },
    });
    this.commentDraft = '';
  }

  async onCommentDelete(comment: ChatComment) {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteChatComment($input: DeleteChatCommentInput!) {
          deleteChatComment(input: $input) {
            success
          }
        }
      `,
      variables: {
        input: {
          id: comment.id,
        },
      },
    });
  }

  async fetchMoreComments() {
    await this.$apollo.queries.chat.fetchMore({
      variables: {
        id: this.chatId,
        pagination: {
          skip: this.chat?.comments.data.length,
          take: 50,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { data: previousComments } = previousResult.chat.comments;
        const { data: newComments, hasMore } = fetchMoreResult.chat.comments;

        return {
          chat: {
            ...previousResult.chat,
            comments: {
              ...previousResult.chat.comments,
              data: [...previousComments, ...newComments],
              hasMore,
            },
          },
        };
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.chat {
  color: $color-theme-red;
  overflow: scroll;

  .form {
    display: flex;
    align-items: flex-end;
    &__input {
      flex: 1;
      margin: 0;
    }
    &__button {
      min-width: 65px;
      margin-left: 16px;
    }
  }

  .comments {
    margin-top: 8px;
    .comment {
      padding: 4px;

      &.admin {
        margin: 8px 0;
        padding-top: 8px;
        padding-bottom: 8px;
        /* color: $color-paper; */
        border: 1px solid $color-theme-red;
      }
      &__date {
      }
      &__body {
        color: $color-theme-yellow;
      }
      &__actions {
        display: inline-block;
        float: right;
        button {
          background: none;
          border: none;
          color: $color-theme-red;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
