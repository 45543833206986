import { v4 as uuid } from 'uuid';
import { Field } from '../../../form/decorators/Field';
import EmbeddedForm from '../../../form/EmbeddedForm';
import { Variant } from '../../../types/api';

export class MeetAndGreetVariantForm extends EmbeddedForm<Variant> {
  key = uuid();

  @Field({ static: true })
  public id?: string;

  @Field()
  public name: string = '';

  @Field()
  public sortOrder = 0;

  constructor(variant?: Variant) {
    super(variant);
    if (variant) {
      this.id = variant.id;
      this.key = variant.id;
      this.name = variant.name;
      this.sortOrder = variant.sortOrder;
    }
  }
}
