import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Product } from '@/types/api';
import gql from 'graphql-tag';
import { ProductForm } from '.';
import { PurchasableFragments } from '../../apollo/fragments/purchasable';
import { VariantForm } from './variant/VariantForm';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateProduct($input: CreateProductInput!) {
        createProduct(input: $input) {
          product {
            ...Purchasable
          }
        }
      }
      ${PurchasableFragments.Purchasable}
    `,
    refetchQueries: ['Purchasables'],
  },
})
export class CreateProductForm extends GraphQLForm<Product> implements ProductForm {
  @Field()
  public title = '';

  @Field()
  public description = '';

  @Field()
  public price?: number;

  @Field()
  public isCitizenRestricted = false;

  @Field()
  public requiredScore: number = 0;

  @Field()
  public image: File | null = null;

  @Field()
  public variantInputs = [new VariantForm()];

  public save() {
    return super.save({
      refetchQueries: ['Products'],
      // optimisticResponse: {
      //   createProduct: {
      //     product: {
      //       id: 'optimistic-id',
      //       title: this.title,
      //       description: this.description,
      //       price: this.price,
      //       isCitizenRestricted: this.isCitizenRestricted,
      //       requiredScore: this.requiredScore,
      //       imageUrl: '',
      //       variants: this.variantInputs.map((a) => ({
      //         id: a.id,
      //         name: a.name,
      //         __typename: 'Variant',
      //       })),
      //       image: null,
      //       __typename: 'Product',
      //     },
      //     __typename: 'CreateProductPayload',
      //   },
      //   __typename: 'Mutation',
      // },
    });
  }
}
