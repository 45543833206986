<template>
  <input
    :type="type"
    v-bind="
      $attrs
      // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    "
    v-on="
      $listeners
      // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    "
    :value="valueAsFormattedDate"
    :min="minAsFormattedDate"
    :max="maxAsFormattedDate"
    @input="onInput"
  />
</template>

<script lang="ts">
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Component, Prop, Vue } from 'vue-property-decorator';

dayjs.extend(utc);

@Component({
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  // Change the v-model event name to `update` to avoid changing
  // the behavior of the native `input` event.
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  model: {
    event: 'update',
  },
})
export default class BaseDateInput extends Vue {
  @Prop({ type: Date }) public value!: Date;

  @Prop({ type: Date }) public min?: Date;
  @Prop({ type: Date }) public max?: Date;

  @Prop({
    type: String,
    validator(value: any) {
      return !value || ['date', 'datetime-local'].includes(value);
    },
    default: 'date',
  })
  private type!: string;

  public get valueAsFormattedDate(): string {
    return this.value && this.formatDate(this.value);
  }

  public get minAsFormattedDate(): string | undefined {
    return this.min && this.formatDate(this.min);
  }

  public get maxAsFormattedDate(): string | undefined {
    return this.max && this.formatDate(this.max);
  }

  private get dateFormat(): string {
    return this.type === 'datetime-local' ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD';
  }

  public formatDate(date: Date): string {
    return dayjs.utc(date).format(this.dateFormat);
  }

  public onInput(e: HTMLInputEvent): void {
    const { valueAsDate: date, value } = e.target;
    if (value.startsWith('2') || value.startsWith('1')) {
      if (this.type === 'datetime-local') {
        this.$emit('update', new Date(value + ':00Z'));
      } else {
        this.$emit('update', date);
      }
    } else {
      this.$emit('update', undefined);
    }
  }
}
</script>

<style lang="scss" scoped></style>
