import { v4 as uuid } from 'uuid';
import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { MuxSimulcast } from '../../types/api';

export class SimulcastForm extends EmbeddedForm<MuxSimulcast> {
  public id = uuid();

  @Field()
  public name = '';

  @Field()
  public url = '';

  @Field()
  public streamKey = '';

  // constructor(simulcast?: MuxSimulcast) {
  //   super(simulcast);
  //   if (simulcast) {
  //     this.id = simulcast.id;
  //     this.name = simulcast.name;
  //     this.url = simulcast.url;
  //     this.streamKey = simulcast.streamKey;
  //   }
  // }
}
