import gql from 'graphql-tag';
import { MuxAssetFragments } from './muxAsset';
import { MuxSimulcastFragments } from './muxSimulcast';

export namespace LiveStreamFragments {
  export const LiveStream = gql`
    fragment LiveStream on LiveStream {
      id
      isCitizenRestricted
      scheduledAt
      streamUrl
      streamKey
      status
      assets {
        ...MuxAsset
      }
      simulcasts {
        ...MuxSimulcast
      }
    }
    ${MuxAssetFragments.MuxAsset}
    ${MuxSimulcastFragments.MuxSimulcast}
  `;
}
