import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { LiveStream } from '../../types/api';
import { SimulcastForm } from './SimulcastForm';

export class LiveStreamForm extends EmbeddedForm<LiveStream> {
  @Field()
  public isCitizenRestricted: boolean = false;

  @Field()
  public scheduledAt?: Date;

  @Field({ name: 'simulcastsInput' })
  public simulcastForms: SimulcastForm[] = [];

  constructor(liveStream?: LiveStream) {
    super(liveStream);
    if (liveStream) {
      this.isCitizenRestricted = liveStream.isCitizenRestricted;
      this.scheduledAt = new Date(liveStream.scheduledAt);
    }
  }
}
