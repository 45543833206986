import { MovieFragments } from '@/apollo/fragments/movie';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Movie } from '@/types/api';
import gql from 'graphql-tag';
import { MovieForm } from '.';
import { VideoForm } from '../video/VideoForm';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateMovie($input: CreateMovieInput!) {
        createMovie(input: $input) {
          movie {
            ...Movie
          }
        }
      }
      ${MovieFragments.Movie}
    `,
    refetchQueries: ['Movies'],
  },
})
export class CreateMovieForm extends GraphQLForm<Movie> implements MovieForm {
  @Field()
  public title = '';

  @Field()
  public body = '';

  @Field()
  public image: File | null = null;

  @Field()
  public published = false;

  @Field({ name: 'video' })
  public videoForm = new VideoForm();

  public save() {
    return super.save({
      optimisticResponse: {
        createMovie: {
          movie: {
            id: 'optimistic-id',
            title: this.title,
            body: this.body,
            imageUrl: null,
            publishedAt: this.published ? new Date() : null,
            __typename: 'Movie',
            video: {
              id: 'optimistic-video-id',
              fileKey: this.videoForm.fileKey,
              isCitizenRestricted: this.videoForm.isCitizenRestricted,
              __typename: 'Video',
            },
          },
          __typename: 'CreateMoviePayload',
        },
        __typename: 'Mutation',
      },
    });
  }
}
