<template>
  <app-cell class="stats" :editable="false" :deletable="false">
    <h3>Secret Gathering Stats</h3>

    <p>
      Secret Gathering Purchases: <strong>{{ meetAndGreetTicketPurchasesCount }}</strong>
    </p>

    <table class="purchases">
      <thead>
        <tr>
          <th></th>
          <th>Product</th>
          <th>Variant</th>
          <th>Purchases</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in meetAndGreetPurchasesByVariant" :key="group.variant.id">
          <td>
            <img :src="group.variant.purchasable?.imageUrl" :alt="group.variant.purchasable?.title" />
          </td>
          <td>{{ group.variant.purchasable?.title }}</td>
          <td>{{ group.variant.name }}</td>
          <td class="purchases">{{ group.purchases }}</td>
        </tr>
      </tbody>
    </table>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { VariantPurchases } from '../../types/api';

@Component({
  components: { AppCell },
  apollo: {
    meetAndGreetTicketPurchasesCount: {
      query: gql`
        {
          meetAndGreetTicketPurchasesCount
        }
      `,
      fetchPolicy: 'cache-and-network',
    },
    meetAndGreetPurchasesByVariant: {
      query: gql`
        {
          meetAndGreetPurchasesByVariant {
            variant {
              id
              name
              purchasable {
                id
                title
                imageUrl
              }
            }
            purchases
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class MeetAndGreetStats extends Vue {
  meetAndGreetTicketPurchasesCount: number | null = null;
  meetAndGreetPurchasesByVariant: VariantPurchases[] = [];
}
</script>

<style lang="scss" scoped>
.stats {
  table {
    margin-top: 16px;
    width: 100%;

    thead tr,
    tbody tr:not(:last-child) {
      border-bottom: 1px solid $color-theme-yellow;
    }

    thead {
      font-weight: bold;

      tr {
        th {
          padding: 8px;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;
          padding: 0 8px;

          &:has(img) {
            padding: 0;
            width: 40px;
          }

          &:last-child {
            text-align: right;
          }
        }

        img {
          margin-top: 4px;
          height: 32px;
          width: 32px;
          object-fit: fit;
        }

        .purchases {
          color: $color-theme-red;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
