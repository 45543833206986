import { PostFragments } from '@/apollo/fragments/post';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Post } from '@/types/api';
import gql from 'graphql-tag';
import { PostForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation CreatePost($input: CreatePostInput!) {
        createPost(input: $input) {
          post {
            ...Post
          }
        }
      }
      ${PostFragments.Post}
    `,
    refetchQueries: ['Posts'],
  },
})
export class CreatePostForm extends GraphQLForm<Post> implements PostForm {
  @Field()
  public title = '';

  @Field()
  public html = '';

  @Field()
  public isNotification = false;

  @Field()
  public image: File | null = null;

  public save() {
    return super.save({
      optimisticResponse: {
        createPost: {
          post: {
            id: 'optimistic-id',
            title: this.title,
            html: this.html,
            image: null,
            __typename: 'Post',
          },
          __typename: 'CreatePostPayload',
        },
        __typename: 'Mutation',
      },
    });
  }
}
