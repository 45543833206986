<template>
  <div class="dashboard">
    <users-stats />
    <meet-and-greet-stats />
  </div>
</template>

<script lang="ts">
import MeetAndGreetStats from '@/components/dashboard/MeetAndGreetStats.vue';
import UsersStats from '@/components/dashboard/UsersStats.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    MeetAndGreetStats,
    UsersStats,
  },
})
export default class Dashboard extends Vue {}
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
