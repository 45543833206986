<template>
  <div id="app">
    <router-view />
    <app-modal />
  </div>
</template>

<script lang="ts">
import AppModal from '@/components/AppModal.vue';

export default {
  name: 'App',
  components: {
    AppModal,
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
</style>
