import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { Video } from '../../types/api';

export class VideoForm extends EmbeddedForm<Video> {
  @Field()
  public fileKey: string = '';

  @Field()
  public isCitizenRestricted: boolean = false;

  constructor(video?: Video) {
    super(video);
    if (video) {
      this.fileKey = video.fileKey;
      this.isCitizenRestricted = video.isCitizenRestricted;
    }
  }
}
