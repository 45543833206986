<template>
  <app-cell class="transaction-cell" :class="{ collapsed: collapsed }">
    <template v-if="collapsed">
      <h3>{{ name }}</h3>
      <p>{{ transaction.date | dayjs('MMM DD, YYYY (HH:mm)') }}</p>
      <p class="credits">
        <span>{{ 'total' in transaction ? transaction.total : transaction.credits }}</span>
        <template v-if="'refundedAt' in transaction">
          <strong v-if="transaction.refundedAt">(REFUNDED)</strong>
        </template>
        <template v-if="'creditsRefunded' in transaction">
          <strong v-if="transaction.creditsRefunded > 0">({{ transaction.creditsRefundable }})</strong>
        </template>
      </p>
    </template>

    <template v-else>
      <dl>
        <div>
          <dt>Type</dt>
          <dd>{{ name }}</dd>
        </div>
        <div>
          <dt>ID</dt>
          <dd>{{ transaction.id }}</dd>
        </div>
        <template v-if="'total' in transaction">
          <div>
            <dt>Total</dt>
            <dd>{{ transaction.total }}</dd>
          </div>
          <div>
            <dt>Quantity</dt>
            <dd>
              {{ transaction.quantity }}
            </dd>
          </div>
          <div>
            <dt>Unit price</dt>
            <dd>{{ transaction.credits }}</dd>
          </div>
        </template>
        <template v-else>
          <div>
            <dt>Credits</dt>
            <dd>{{ transaction.credits }}</dd>
          </div>
        </template>
        <template v-if="'creditsRefunded' in transaction && transaction.creditsRefunded > 0">
          <div>
            <dt>Credits refunded</dt>
            <dd>{{ transaction.creditsRefunded }}</dd>
          </div>
          <div>
            <dt>Credits refundable</dt>
            <dd>{{ transaction.creditsRefundable }}</dd>
          </div>
        </template>
        <div>
          <dt>Transaction date</dt>
          <dd>{{ transaction.date | dayjs('MMM DD, YYYY (HH:mm)') }}</dd>
        </div>
        <div v-if="'refundedAt' in transaction && transaction.refundedAt">
          <dt>Refunded at</dt>
          <dd>{{ transaction.refundedAt | dayjs('MMM DD, YYYY (HH:mm)') }}</dd>
        </div>
        <template v-if="show">
          <div>
            <dt>Show</dt>
            <dd>
              <router-link :to="{ path: '/shows', hash: show.id.toString() }"
                >{{ show.address.venue }}, {{ show.showDate | dayjs('MMM DD, YYYY (HH:mm)') }}</router-link
              >
            </dd>
          </div>
        </template>
        <template v-if="'amountInCents' in transaction">
          <div>
            <dt>Amount in cents</dt>
            <dd>{{ transaction.amountInCents }}</dd>
          </div>
          <div v-if="transaction.currency">
            <dt>Currency</dt>
            <dd>{{ transaction.currency }}</dd>
          </div>
        </template>
      </dl>
      <div v-if="'receiptUrl' in transaction && transaction.receiptUrl">
        <a :href="transaction.receiptUrl" target="_blank">Show receipt</a>
      </div>
      <ul class="box" v-if="'orderItems' in transaction">
        <h3>Items</h3>
        <li v-for="item in transaction.orderItems" :key="item.id" class="box">
          <dl>
            <div>
              <dt>Variant id</dt>
              <dd>{{ item.variant.id }}</dd>
            </div>
            <div>
              <dt>Variant</dt>
              <dd>{{ item.variant.name }}</dd>
            </div>
          </dl>
        </li>
      </ul>
      <ul class="box" v-if="'refunds' in transaction && transaction.refunds.length > 0">
        <h3>Refunds</h3>
        <li v-for="refund in transaction.refunds" :key="refund.id" class="box">
          <dl>
            <div>
              <dt>Date</dt>
              <dd>{{ refund.date | dayjs('MMM DD, YYYY (HH:mm)') }}</dd>
            </div>
            <div>
              <dt>Credits</dt>
              <dd>{{ refund.credits }}</dd>
            </div>
            <div>
              <dt>Amount in cents</dt>
              <dd>{{ refund.amountInCents }}</dd>
            </div>
          </dl>
        </li>
      </ul>
    </template>
    <template slot="actions">
      <base-button @click="onToggleExpand()">
        {{ collapsed ? 'Expand' : 'Collapse' }}
      </base-button>
      <base-button v-if="isRefundable" @click="onRefund()" :disabled="isRefunded || isFulfilled">Refund</base-button>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Deposit, MeetAndGreetTicketPurchase, Order, Ticket } from '../types/api';
import { isDeposit, isMeetAndGreetTicketPurchase, isOrder, isTicket } from '../utils/assessInterfaceType';

@Component({
  components: { AppCell },
})
export default class TransactionCell extends Vue {
  @Prop({ required: true }) transaction!: Deposit | Ticket | Order | MeetAndGreetTicketPurchase;

  collapsed = true;

  get name() {
    if (isDeposit(this.transaction)) return 'Deposit';
    if (isTicket(this.transaction)) return 'Ticket';
    if (isOrder(this.transaction)) return 'Order';
    if (isMeetAndGreetTicketPurchase(this.transaction)) return 'Secret Gathering ticket';
    return 'Unknown';
  }

  get show() {
    if (isMeetAndGreetTicketPurchase(this.transaction)) return this.transaction.ticket.show;
    if (isTicket(this.transaction)) return this.transaction.show;
    return null;
  }

  get isRefundable(): boolean {
    return (
      isDeposit(this.transaction) ||
      isTicket(this.transaction) ||
      isOrder(this.transaction) ||
      isMeetAndGreetTicketPurchase(this.transaction)
    );
  }

  get isRefunded(): boolean {
    if (isDeposit(this.transaction)) return this.transaction.creditsRefundable <= 0;
    if (isTicket(this.transaction) || isOrder(this.transaction) || isMeetAndGreetTicketPurchase(this.transaction))
      return this.transaction.refundedAt != null;
    return false;
  }

  get isFulfilled(): boolean {
    if (isOrder(this.transaction)) return this.transaction.orderFulfillments.length > 0;
    return false;
  }

  onToggleExpand() {
    this.collapsed = !this.collapsed;
  }

  onRefund() {
    this.$emit('refund', this.transaction);
  }
}
</script>

<style lang="scss" scoped>
.transaction-cell {
  &.collapsed {
    display: flex;

    .credits {
      span {
        margin-right: 8px;
      }
    }
  }

  dl {
    > div {
      display: flex;
      justify-content: space-between;
      dt {
        margin-right: 24px;
      }
      dd {
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .box {
    margin: 16px 0;
    padding: 16px;
    border: 1px solid $color-theme-yellow;

    h3 {
      font-weight: bold;
    }
  }
}
</style>
