import { BlogFragments } from '@/apollo/fragments/blog';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Blog } from '@/types/api';
import gql from 'graphql-tag';
import { BlogForm } from '.';
import { ChatForm } from '../chat/ChatForm';
import { LiveStreamForm } from '../liveStream/LiveStreamForm';
import { VideoForm } from '../video/VideoForm';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdateBlog($input: UpdateBlogInput!) {
        updateBlog(input: $input) {
          blog {
            ...Blog
          }
        }
      }
      ${BlogFragments.Blog}
    `,
  },
})
export class UpdateBlogForm extends GraphQLForm<Blog> implements BlogForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public body: string;

  @Field()
  public image: File | null;

  @Field()
  public sortOrder: number;

  @Field({ name: 'video' })
  public videoForm: VideoForm | null;

  @Field({ name: 'liveStream' })
  public liveStreamForm: LiveStreamForm | null;

  @Field({ name: 'chat' })
  public chatForm: ChatForm | null;

  constructor(blog: Blog) {
    super(blog);
    this.id = blog.id;
    this.title = blog.title;
    this.body = blog.body;
    this.sortOrder = blog.sortOrder;
    this.videoForm = blog.video ? new VideoForm(blog.video) : null;
    this.liveStreamForm = blog.liveStream ? new LiveStreamForm(blog.liveStream) : null;
    this.chatForm = blog.chat ? new ChatForm(blog.chat) : null;
    this.image = null;
  }
}
