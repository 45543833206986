<template>
  <main class="login">
    <img class="logo" alt="Avatar country logo" src="@/assets/logo.png" />
    <h1>Login required</h1>
    <form @submit.prevent="sendLoginRequest" class="login__form">
      <base-form-input class="form__input" label="Email">
        <input v-model="email" type="email" placeholder="john@doe.com" />
      </base-form-input>
      <base-form-input class="form__input" label="Password">
        <input v-model="password" type="password" placeholder="password" />
      </base-form-input>
      <strong v-if="failed">Failed to login.</strong>
      <base-button>Login</base-button>
    </form>
  </main>
</template>

<script lang="ts">
import { IsEmail } from 'class-validator';
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Login extends Vue {
  @IsEmail()
  public email = '';

  public password = '';

  public failed = false;

  public async sendLoginRequest() {
    try {
      this.failed = false;
      await this.$apollo.mutate({
        mutation: gql`
          mutation Login($input: LoginInput!) {
            login(input: $input) {
              user {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            email: this.email,
            password: this.password,
          },
        },
      });
      this.$router.push('/').catch(() => {});
    } catch {
      this.failed = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;

  padding: 32px;

  h1 {
    margin: 32px 0;
  }
  &__form {
    width: 240px;
    .form__input {
      width: 100%;
      margin-bottom: 8px;
    }
    button {
      margin-top: 8px;
      width: 100%;
    }
  }

  .logo {
    margin: 32px 0;
    width: 400px;
    max-width: 100%;
  }
}
</style>
