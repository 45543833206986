import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { TicketSales } from '../../types/api';

export class TicketSalesForm extends EmbeddedForm<TicketSales> {
  @Field()
  public price: number = 0;

  @Field()
  public limit: number = 0;

  @Field()
  public availableFrom?: Date;

  @Field()
  public availableUntil?: Date;

  @Field()
  public note?: string | null;

  constructor(ticketSales?: TicketSales) {
    super(ticketSales);
    if (ticketSales) {
      this.price = ticketSales.price;
      this.limit = ticketSales.limit;
      this.availableFrom = new Date(ticketSales.availableFrom);
      this.availableUntil = ticketSales.availableUntil && new Date(ticketSales.availableUntil);
      this.note = ticketSales.note;
    }
  }
}
