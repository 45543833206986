<template>
  <app-cell
    class="question-cell"
    :edit="!!form"
    :deletable="!!question"
    :loading="!!form && form.isLoading"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="question">
      <div class="question-cell__content">
        <h2 class="question-cell__title">{{ question.title }}</h2>
        <p>
          <span v-html="question.body" />
        </p>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="question-cell__form">
        <base-form-input class="form__input" label="Question">
          <input v-model="form.title" placeholder="Question" />
        </base-form-input>
        <base-form-input v-if="form.sortOrder != null" class="form__input" label="Sort order">
          <input v-model.number="form.sortOrder" placeholder="Sort order" type="number" />
        </base-form-input>
        <base-form-input class="form__input" label="Answer">
          <app-html-editor v-model="form.body" placeholder="Answer" />
        </base-form-input>
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import { CreateQuestionForm, QuestionForm, UpdateQuestionForm } from '@/forms/question';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Question } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor },
})
export default class QuestionCell extends Vue {
  @Prop()
  public question?: Question;

  public form = null as QuestionForm | null;

  public onEdit() {
    this.form = this.question ? new UpdateQuestionForm(this.question) : new CreateQuestionForm();
  }

  public onCancel() {
    this.closeForm();
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public async onDelete() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteQuestion($input: DeleteQuestionInput!) {
          deleteQuestion(input: $input) {
            success
          }
        }
      `,
      variables: { input: { id: this.question!.id } },
      refetchQueries: ['Questions'],
    });
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.question) {
      this.form = new CreateQuestionForm();
    }
  }
}
</script>

<style lang="scss" scoped>
.question-cell {
  &__title {
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
