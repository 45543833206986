<template>
  <div class="blogs">
    <base-button class="blogs__add" v-if="!shouldAddBlog" @click="shouldAddBlog = true">Add blog</base-button>
    <transition-group name="list" mode="out-in" class="blogs__list" tag="ul">
      <app-blog-cell v-if="shouldAddBlog" key="add" class="list__blog" @close="shouldAddBlog = false" />
      <app-blog-cell v-for="blog in blogs" :key="blog.id" :blog="blog" class="list__blog" @watch="onWatch" />
    </transition-group>
    <app-video-player v-if="streamUrl" :streamUrl="streamUrl" class="player" @close="streamUrl = null" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { Blog } from '../types/api';
import AppBlogCell from '@/components/BlogCell.vue';
import AppVideoPlayer from '@/components/VideoPlayer.vue';
import { BlogFragments } from '../apollo/fragments/blog';

@Component({
  components: { AppBlogCell, AppVideoPlayer },
  apollo: {
    blogs: {
      query: gql`
        query Blogs {
          blogs {
            ...Blog
          }
        }
        ${BlogFragments.Blog}
      `,
      fetchPolicy: 'cache-and-network',
      subscribeToMore: [
        {
          document: gql`
            subscription {
              liveStreamStatusChanged {
                liveStream {
                  id
                  status
                }
              }
            }
          `,
        },
        {
          document: gql`
            subscription {
              simulcastStatusChanged {
                muxSimulcast {
                  id
                  status
                }
              }
            }
          `,
        },
      ],
    },
  },
})
export default class Studio extends Vue {
  blogs: Blog[] = [];
  streamUrl: string | null = null;
  shouldAddBlog = false;

  public onWatch(streamUrl: string) {
    this.streamUrl = streamUrl;
  }
}
</script>

<style lang="scss" scoped>
.blogs {
  &__add {
    margin-bottom: 8px;
  }
}

.blogs__list {
}

.list__blog {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
