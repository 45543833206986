import gql from 'graphql-tag';

export namespace MuxSimulcastFragments {
  export const MuxSimulcast = gql`
    fragment MuxSimulcast on MuxSimulcast {
      id
      name
      url
      streamKey
      status
      webhookStatus
    }
  `;
}
