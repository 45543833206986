import gql from 'graphql-tag';

export namespace MuxAssetFragments {
  export const MuxAsset = gql`
    fragment MuxAsset on MuxAsset {
      id
      streamUrl
      durationInSeconds
      createdAt
      subtitles {
        id
        name
        languageCode
        status
      }
    }
  `;
}
