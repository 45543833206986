import 'reflect-metadata';
import { FIELD_METADATA_KEY } from '../constants';
import EmbeddedForm from '../EmbeddedForm';
import { FieldMetadata, FieldOptions } from '../types';

export function Field<T extends any>(options: Partial<FieldOptions<T>> = {}): PropertyDecorator {
  return (target, key) => {
    const defaultOptions: FieldOptions<T | any> = {
      static: false,
      dirtyIf: (original, value, property) => original[property] !== value,
      transform: (value, property) => {
        if (value === '') return null;
        const isEmbeddedForm = value instanceof EmbeddedForm;
        if (isEmbeddedForm) return value.filteredFieldsData;
        const isEmbeddedFormArray = Array.isArray(value) && value.every((a) => a instanceof EmbeddedForm);
        if (isEmbeddedFormArray) return value.map((a: any) => a.filteredFieldsData);
        return value;
      },
      name: key.toString(),
    };
    const properties: Array<FieldMetadata<T>> = Reflect.getOwnMetadata(FIELD_METADATA_KEY, target) || [];
    if (!properties.some((a) => a.key === key)) {
      properties.push({
        key: key.toString(),
        options: {
          ...defaultOptions,
          ...options,
        },
      });
    }
    Reflect.defineMetadata(FIELD_METADATA_KEY, properties, target);
  };
}
