import gql from 'graphql-tag';
import { VariantFragments } from './variant';

export namespace PurchasableFragments {
  export const Purchasable = gql`
    fragment Purchasable on IPurchasable {
      id
      title
      description
      price
      imageUrl
      variants {
        ...Variant
      }
      ... on Product {
        isCitizenRestricted
        sortOrder
        requiredScore
      }
      ... on MeetAndGreetProduct {
        sortOrder
        visibleForLoyal
        visibleForPurchase
        visibleForRandom
      }
    }
    ${VariantFragments.Variant}
  `;
}
