import gql from 'graphql-tag';

export namespace CitizenshipFragments {
  export const Citizenship = gql`
    fragment Citizenship on Citizenship {
      id
      title
      description
      imageUrl
      yearlyPrice
      monthlyPrice
    }
  `;
}
