<template>
  <div class="documents">
    <app-document-cell v-if="termsAndConditions" :document="termsAndConditions" class="list__document" />
    <app-document-cell v-if="about" :document="about" class="list__document" />
    <app-document-cell v-if="cookies" :document="cookies" class="list__document" />
    <app-document-cell v-if="meetAndGreetTerms" :document="meetAndGreetTerms" class="list__document" />
  </div>
</template>

<script lang="ts">
import AppDocumentCell from '@/components/DocumentCell.vue';
import { IDocument } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { DocumentFragments } from '../apollo/fragments/document';

@Component({
  components: { AppDocumentCell },
  apollo: {
    termsAndConditions: {
      query: gql`
        query Terms {
          termsAndConditions {
            ...Document
          }
        }
        ${DocumentFragments.Document}
      `,
      fetchPolicy: 'cache-and-network',
    },
    about: {
      query: gql`
        query About {
          about {
            ...Document
          }
        }
        ${DocumentFragments.Document}
      `,
      fetchPolicy: 'cache-and-network',
    },
    cookies: {
      query: gql`
        query Cookies {
          cookies {
            ...Document
          }
        }
        ${DocumentFragments.Document}
      `,
      fetchPolicy: 'cache-and-network',
    },
    meetAndGreetTerms: {
      query: gql`
        query MeetAndGreetTerms {
          meetAndGreetTerms {
            ...Document
          }
        }
        ${DocumentFragments.Document}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class Documents extends Vue {
  termsAndConditions: IDocument | null = null;
  about: IDocument | null = null;
  cookies: IDocument | null = null;
  meetAndGreetTerms: IDocument | null = null;
}
</script>

<style lang="scss" scoped>
.documents {
  &__add {
    margin-bottom: 8px;
    margin-right: 8px;
  }
}

.documents__list {
}

.list__document {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
