import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { MeetAndGreet } from '../../types/api';

export class MeetAndGreetForm extends EmbeddedForm<MeetAndGreet> {
  @Field()
  public price: number = 0;

  @Field()
  public loyalQuota: number = 2;

  @Field()
  public randomQuota: number = 0;

  @Field()
  public purchaseQuota: number = 8;

  @Field()
  public purchaseQuotaNotificationThreshold: number = 2;

  @Field()
  public releaseDate: Date = new Date();

  @Field()
  public isApplicationsEnabled: boolean = true;

  @Field()
  public isRemindersEnabled: boolean = false;

  @Field()
  public instructions?: string | null;

  constructor(meetAndGreet?: MeetAndGreet) {
    super(meetAndGreet);
    if (meetAndGreet) {
      this.price = meetAndGreet.price;
      this.randomQuota = meetAndGreet.randomQuota;
      this.loyalQuota = meetAndGreet.loyalQuota;
      this.purchaseQuota = meetAndGreet.purchaseQuota;
      this.purchaseQuotaNotificationThreshold = meetAndGreet.purchaseQuotaNotificationThreshold;
      this.releaseDate = new Date(meetAndGreet.releaseDate);
      this.instructions = meetAndGreet.instructions;
      this.isApplicationsEnabled = meetAndGreet.isApplicationsEnabled;
      this.isRemindersEnabled = meetAndGreet.isRemindersEnabled;
    }
  }
}
