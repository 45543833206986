
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "IDocument",
        "possibleTypes": [
          {
            "name": "AboutDocument"
          },
          {
            "name": "CookiesDocument"
          },
          {
            "name": "MeetAndGreetTermsDocument"
          },
          {
            "name": "TermsDocument"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "IEnvironment",
        "possibleTypes": [
          {
            "name": "WebEnvironment"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ITransaction",
        "possibleTypes": [
          {
            "name": "Order"
          },
          {
            "name": "Ticket"
          },
          {
            "name": "MeetAndGreetTicketPurchase"
          },
          {
            "name": "Refund"
          },
          {
            "name": "Deposit"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "IAddress",
        "possibleTypes": [
          {
            "name": "UserAddress"
          },
          {
            "name": "ShowAddress"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "IOrderItem",
        "possibleTypes": [
          {
            "name": "OrderPhoto"
          },
          {
            "name": "OrderCitizenship"
          },
          {
            "name": "OrderExpress"
          },
          {
            "name": "OrderMeetAndGreetProduct"
          },
          {
            "name": "OrderPassport"
          },
          {
            "name": "OrderProduct"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "IPurchasable",
        "possibleTypes": [
          {
            "name": "MeetAndGreetProduct"
          },
          {
            "name": "Citizenship"
          },
          {
            "name": "ExpressShipping"
          },
          {
            "name": "Passport"
          },
          {
            "name": "PhotoPrint"
          },
          {
            "name": "Product"
          }
        ]
      }
    ]
  }
};
      export default result;
    