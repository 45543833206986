import { createUploadLink } from 'apollo-upload-client';

const options = {
  uri: '/api/graphql',
  credentials: 'include',
  headers: {
    'apollo-require-preflight': true,
  },
};

export const httpLink = createUploadLink(options);
