<template>
  <div class="posts">
    <base-button class="posts__add" v-if="!shouldAddPost" @click="shouldAddPost = true">Add post</base-button>
    <transition-group name="list" mode="out-in" class="posts__list" tag="ul">
      <app-post-cell v-if="shouldAddPost" key="add" class="list__post" @close="shouldAddPost = false" />
      <app-post-cell v-for="post in posts" :key="post.id" :post="post" class="list__post" />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { Post } from '../types/api';
import AppPostCell from '@/components/PostCell.vue';
import { PostFragments } from '../apollo/fragments/post';

@Component({
  components: { AppPostCell },
  apollo: {
    posts: {
      query: gql`
        query Posts {
          posts {
            ...Post
          }
        }
        ${PostFragments.Post}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class News extends Vue {
  posts: Post[] = [];
  shouldAddPost = false;
}
</script>

<style lang="scss" scoped>
.posts {
  &__add {
    margin-bottom: 8px;
  }
}

.posts__list {
}

.list__post {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
