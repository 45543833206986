import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Vue from 'vue';
import createProvider from './apollo';
import App from './App.vue';
import { getEnvironment } from './getEnvironment';
import { ModalPlugin } from './plugins/modal';
import router from './router';
import { importBaseComponents } from './utils/importBaseComponents';

dayjs.extend(utc);

Vue.config.productionTip = false;

importBaseComponents();

Vue.filter('dayjs', (value: any, format: string) => dayjs(value).format(format));
Vue.filter('dayjs.utc', (value: any, format: string) => dayjs.utc(value).format(format));
Vue.filter('duration', (value: number | null): string | null => {
  if (value) {
    const h = Math.floor(value / 3600);
    const m = Math.floor((value % 3600) / 60);
    const s = value % 60;
    return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':');
  } else {
    return null;
  }
});

Vue.use(ModalPlugin);

getEnvironment().then((env) => {
  Sentry.init({
    dsn: env.SENTRY_DSN || undefined,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: env.PIPELINE_STAGE || undefined,
    enabled: !!env.PIPELINE_STAGE,
  });
  createProvider().then((provider) => {
    new Vue({
      router,
      apolloProvider: provider,
      render: (h) => h(App),
    }).$mount('#app');
  });
});
