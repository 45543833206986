<template>
  <div class="video-player">
    <video ref="videoPlayer" class="video-js"></video>
    <base-button class="video-player__close" @click="$emit('close')">Close</base-button>
  </div>
</template>

<script lang="ts">
import videojs, { VideoJsPlayer } from 'video.js';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class VideoPlayer extends Vue {
  @Prop({ required: true })
  public streamUrl!: string;

  public player?: VideoJsPlayer;

  private options = {
    sources: [{ src: this.streamUrl, type: 'application/x-mpegURL' }],
    width: 800, // this.$root.$el.clientWidth,
    controls: true,
  };

  public mounted() {
    this.player = videojs(this.$refs.videoPlayer as Element, this.options);
  }
  public beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/video-js.scss';

.video-player {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  &__close {
    width: 200px;
  }

  .vjs-picture-in-picture-control {
    display: none;
  }
  /* Prevent CC settings menu item from displaying */
  .vjs-texttrack-settings {
    display: none;
  }
  .vjs-selected {
    background-color: $color-theme-red !important;
  }
  .vjs-menu-item-text {
    font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
  }
}
</style>
