import { globalModalService } from '@/plugins/modal';
import { onError } from 'apollo-link-error';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { message, extensions } = error;
      switch (extensions?.code) {
        case 'BAD_USER_INPUT': {
          globalModalService.alert({
            title: 'Bad user input',
            message: extensions?.validationErrors
              ?.flatMap((e: any) => (e.constraints ? Object.values(e.constraints) : []))
              .join('\n'),
          });
          break;
        }
        case 'Internal server error':
        case 'INTERNAL_SERVER_ERROR':
          globalModalService.alert({
            title: 'Internal server error',
            message: extensions?.message || message,
          });
          break;
        default:
          globalModalService.alert({
            title: 'Server error',
            message: extensions?.message || message,
          });
          break;
      }
    }
  }
  if (networkError) {
    globalModalService.alert({ title: 'Network error', message: networkError.message });
  }
});
