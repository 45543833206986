import { DocumentFragments } from '@/apollo/fragments/document';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { IDocument, TermsDocument } from '@/types/api';
import gql from 'graphql-tag';
import { DocumentForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdateTerms($input: UpdateTermsInput!) {
        updateTerms(input: $input) {
          terms {
            ...Document
          }
        }
      }
      ${DocumentFragments.Document}
    `,
    refetchQueries: ['Terms'],
  },
})
export class UpdateTermsForm extends GraphQLForm<IDocument> implements DocumentForm {
  @Field()
  public html: string;

  constructor(old: TermsDocument) {
    super();
    this.html = old.html;
  }
}
