<template>
  <app-cell class="user-cell" :editable="false" :deletable="false">
    <template slot="content">
      <div class="user-cell__content">
        <div class="data">
          <dl>
            <div>
              <dt>ID</dt>
              <dd>
                <a :href="googleAnalyticsRef" target="_blank">{{ user.id }}</a>
              </dd>
            </div>
            <div v-if="user.stripeId">
              <dt>Stripe ID</dt>
              <dd>
                <a :href="stripeUrl" target="_blank">{{ user.stripeId }}</a>
              </dd>
            </div>
            <div>
              <dt>Username</dt>
              <dd>{{ user.username }}</dd>
            </div>
            <div>
              <dt>Email</dt>
              <dd>{{ user.email }}</dd>
            </div>
            <div>
              <dt>Role</dt>
              <dd>{{ user.role }}</dd>
            </div>
            <div v-if="user.score">
              <dt>Score</dt>
              <dd>{{ user.score && user.score.total }}</dd>
            </div>
            <div v-if="user.citizenshipExpiresAt">
              <dt>Citizenship expiration</dt>
              <dd>
                {{ user.citizenshipExpiresAt | dayjs('MMM DD, YYYY (HH:mm)') }}
              </dd>
            </div>
            <div v-if="user.citizenshipRemindedAt">
              <dt>Citizenship last reminded at</dt>
              <dd>
                {{ user.citizenshipRemindedAt | dayjs('MMM DD, YYYY (HH:mm)') }}
              </dd>
            </div>
            <div v-if="isCitizen">
              <dt>Consecutive citizen since</dt>
              <dd>{{ user.citizenSince | dayjs('MMM DD, YYYY (HH:mm)') }}</dd>
            </div>
            <!-- <div v-if="isCitizen">
              <dt>Can renew citizenship at</dt>
              <dd>
                {{ user.canRenewCitizenshipAt | dayjs('MMM DD, YYYY (HH:mm)') }}
              </dd>
            </div> -->
            <div>
              <dt>Registered at</dt>
              <dd>{{ user.createdAt | dayjs('MMM DD, YYYY (HH:mm)') }}</dd>
            </div>
            <div>
              <dt>Last updated at</dt>
              <dd>{{ user.updatedAt | dayjs('MMM DD, YYYY (HH:mm)') }}</dd>
            </div>

            <dl class="box" v-if="user.address">
              <div>
                <h3>Address</h3>
              </div>
              <div>
                <dt>Name</dt>
                <dd>{{ user.address.name }}</dd>
              </div>
              <div>
                <dt>First address line</dt>
                <dd>{{ user.address.firstAddressLine }}</dd>
              </div>
              <div>
                <dt>Second address line</dt>
                <dd>{{ user.address.secondAddressLine }}</dd>
              </div>
              <div>
                <dt>Postal code</dt>
                <dd>{{ user.address.postalCode }}</dd>
              </div>
              <div>
                <dt>City</dt>
                <dd>{{ user.address.city }}</dd>
              </div>
              <div>
                <dt>Administrative area</dt>
                <dd>{{ user.address.administrativeArea }}</dd>
              </div>
              <div>
                <dt>Country</dt>
                <dd>{{ user.address.country.name }}</dd>
              </div>
              <div>
                <dt>Phone</dt>
                <dd>{{ user.phone || user.address.phone }}</dd>
              </div>
            </dl>
          </dl>
        </div>

        <div class="image-wrapper">
          <img v-if="user.imageUrl" :src="user.imageUrl" />
        </div>
      </div>

      <ul class="transactions box">
        <h3>Transactions</h3>
        <p class="subtitle">
          <span>Balance:</span>
          <strong>{{ user.wallet.balance }}</strong>
        </p>
        <li v-for="transaction in user.wallet.transactions" :key="transaction.id">
          <app-transaction-cell :transaction="transaction" @refund="$emit('refund', $event)"></app-transaction-cell>
        </li>
      </ul>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppTransactionCell from '@/components/TransactionCell.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User, UserRole } from '../types/api';

@Component({
  components: { AppCell, AppTransactionCell },
})
export default class UserCell extends Vue {
  @Prop({ required: true }) user!: User;

  get isCitizen(): boolean {
    return this.user.role === UserRole.Citizen;
  }

  get stripeUrl(): string | null {
    if (!this.user.stripeId) {
      return null;
    }

    return `https://dashboard.stripe.com/customers/${this.user.stripeId}`;
  }

  get googleAnalyticsRef(): string {
    return `https://analytics.google.com/analytics/web/#/report/visitors-user-activity/a139929781w212433714p206082174/_r.userId=${this.user.id}&_r.userListReportStates=&_r.userListReportId=visitors-user-id/`;
  }
}
</script>

<style lang="scss" scoped>
.user-cell {
  &__content {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    justify-content: space-between;

    .data {
      max-width: 800px;
      margin-right: 24px;
      flex: 1 1 500px;
    }

    dl {
      > div {
        display: flex;
        justify-content: space-between;
        dt {
          margin-right: 24px;
        }
        dd {
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .image-wrapper {
      max-width: 200px;
      flex: 0 0 200px;
      margin-bottom: 24px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .box {
    margin: 16px 0;
    padding: 16px;
    border: 1px solid $color-theme-red;

    h3 {
      font-weight: bold;
    }
  }

  ul.transactions {
    .subtitle {
      span {
        margin-right: 8px;
      }
    }
    li {
      margin: 16px 0;
    }
  }
}
</style>
