import { PostFragments } from '@/apollo/fragments/post';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Post } from '@/types/api';
import gql from 'graphql-tag';
import { PostForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation Update($input: UpdatePostInput!) {
        updatePost(input: $input) {
          post {
            ...Post
          }
        }
      }
      ${PostFragments.Post}
    `,
  },
})
export class UpdatePostForm extends GraphQLForm<Post> implements PostForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public html: string;

  @Field()
  public isNotification: boolean;

  @Field()
  public image: File | null = null;

  constructor(post: Post) {
    super(post);
    this.id = post.id;
    this.title = post.title;
    this.html = post.html;
    this.isNotification = post.isNotification;
  }
}
