import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { MuxAsset } from '@/types/api';
import gql from 'graphql-tag';

@Form({
  apollo: {
    mutation: gql`
      mutation AddSubtitleTrack($input: AddSubtitleTrackInput!) {
        addSubtitleTrack(input: $input) {
          success
        }
      }
    `,
    refetchQueries: ['Movies'],
  },
})
export class AddSubtitleForm extends GraphQLForm<MuxAsset> {
  @Field({ static: true })
  public assetId: string;

  @Field()
  public name = '';

  @Field()
  public languageCode = '';

  @Field()
  public file: File | null = null;

  constructor(assetId: string) {
    super();
    this.assetId = assetId;
  }
}
