import { MovieFragments } from '@/apollo/fragments/movie';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Movie } from '@/types/api';
import gql from 'graphql-tag';
import { MovieForm } from '.';
import { VideoForm } from '../video/VideoForm';

@Form({
  apollo: {
    mutation: gql`
      mutation Update($input: UpdateMovieInput!) {
        updateMovie(input: $input) {
          movie {
            ...Movie
          }
        }
      }
      ${MovieFragments.Movie}
    `,
  },
})
export class UpdateMovieForm extends GraphQLForm<Movie> implements MovieForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public body: string;

  @Field()
  public published: boolean;

  @Field()
  public image: File | null;

  @Field()
  public sortOrder: number;

  @Field({ name: 'video' })
  public videoForm: VideoForm;

  constructor(movie: Movie) {
    super(movie);
    this.id = movie.id;
    this.title = movie.title;
    this.body = movie.body;
    this.sortOrder = movie.sortOrder;
    this.videoForm = new VideoForm(movie.video);
    this.image = null;
    this.published = movie.publishedAt !== null;
  }
}
