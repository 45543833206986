import gql from 'graphql-tag';
import { apolloClient } from './apollo/client';
import { Query, WebEnvironment } from './types/api';

let e: Pick<WebEnvironment, 'SENTRY_DSN' | 'PIPELINE_STAGE'>;

export const getEnvironment = async (): Promise<typeof e> => {
  if (e) return e;
  const { data, errors } = await apolloClient.query<Pick<Query, 'webEnvironment'>>({
    query: gql`
      {
        webEnvironment {
          SENTRY_DSN
          PIPELINE_STAGE
        }
      }
    `,
  });
  if (errors) throw errors;
  return (e = data.webEnvironment);
};
