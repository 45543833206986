import Vue from 'vue';
import Router from 'vue-router';
import Admin from './layouts/Admin.vue';
import { adminGuard, loginGuard } from './middleware/guards';
import Cinema from './views/Cinema.vue';
import Dashboard from './views/Dashboard.vue';
import Documents from './views/Documents.vue';
import Login from './views/Login.vue';
import News from './views/News.vue';
import Questions from './views/Questions.vue';
import Shows from './views/Shows.vue';
import Store from './views/Store.vue';
import Studio from './views/Studio.vue';
import Users from './views/Users.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: loginGuard,
    },
    {
      path: '/',
      name: 'admin',
      component: Admin,
      beforeEnter: adminGuard,
      redirect: '/dashboard',
      children: [
        { path: '/dashboard', name: 'dashboard', component: Dashboard },
        { path: '/news', name: 'news', component: News },
        { path: '/questions', name: 'questions', component: Questions },
        { path: '/cinema', name: 'cinema', component: Cinema },
        { path: '/studio', name: 'studio', component: Studio },
        { path: '/store', name: 'store', component: Store },
        { path: '/shows', name: 'shows', component: Shows },
        { path: '/docs', name: 'Documents', component: Documents },
        { path: '/users', name: 'users', component: Users },
      ],
    },
  ],
});
