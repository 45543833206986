import 'reflect-metadata';
import { FIELD_METADATA_KEY } from './constants';
import { FieldMetadata } from './types';

export default abstract class BaseForm<T> {
  public get isDirty(): boolean {
    return this.dirtyFields.length > 0;
  }

  private get fields(): Array<FieldMetadata<T>> {
    return Reflect.getMetadata(FIELD_METADATA_KEY, this) || [];
  }

  private get staticFields(): Array<FieldMetadata<T>> {
    return this.fields.filter((field) => {
      return field.options.static;
    });
  }

  private get dirtyFields(): Array<FieldMetadata<T>> {
    const self = this as any;
    return this.fields.filter((field) => {
      const value = self[field.key];
      return !this.original || field.options.dirtyIf(this.original, value, field.key);
    });
  }

  private get filteredFields(): Array<FieldMetadata<T>> {
    return [...this.staticFields, ...this.dirtyFields];
  }

  public get filteredFieldsData(): Record<string, any> {
    const self = this as any;
    return this.filteredFields.reduce<Record<string, any>>((res, { key, options }) => {
      const value = self[key];
      const transformedValue = options.transform(value, key);
      res[options.name] = transformedValue;
      return res;
    }, {});
  }

  constructor(private original?: T) {}
}
