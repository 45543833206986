import { apolloClient } from '@/apollo/client';
import { MutationOptions } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { GraphQLError } from 'graphql';
import 'reflect-metadata';
import { getGraphQLErrors } from '../utils/errors';
import BaseForm from './BaseForm';
import { DecoratedForm, FormOptions } from './types';

export default abstract class GraphQLForm<T, P extends Record<any, any> = any>
  extends BaseForm<T>
  implements DecoratedForm
{
  public _options: Partial<FormOptions> = {};
  public isLoading = false;
  public errors?: GraphQLError[];

  public get errorMessages(): string[] | undefined {
    return this.errors && this.errors.map((e) => e.message);
  }

  public async save(mutationOptions: Partial<MutationOptions> = {}): Promise<FetchResult<P>> {
    const formApolloOptions = this._options.apollo;
    const mutation = mutationOptions.mutation || (formApolloOptions && formApolloOptions.mutation);
    if (!mutation) {
      throw new Error('Mutation options not defined in @Form decorator options');
    }
    const apolloOptions: MutationOptions = {
      mutation,
      ...formApolloOptions,
      variables: {
        input: this.filteredFieldsData,
      },
      ...mutationOptions,
    };
    try {
      this.errors = undefined;
      this.isLoading = true;
      const result = await apolloClient.mutate<any>(apolloOptions);
      return result;
    } catch (e: any) {
      this.errors = getGraphQLErrors(e);
      throw e;
    } finally {
      this.isLoading = false;
    }
  }
}
