import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { ShowAddress } from '../../types/api';

export class ShowAddressForm extends EmbeddedForm<ShowAddress> {
  @Field()
  public venue: string = '';

  @Field()
  public city: string = '';

  @Field()
  public countryId: string = 'US';

  @Field()
  public administrativeArea?: string;

  constructor(address?: ShowAddress) {
    super(address);
    if (address) {
      this.venue = address.venue;
      this.city = address.city;
      this.countryId = address.country.id;
      this.administrativeArea = address.administrativeArea || undefined;
    }
  }
}
