import { BlogFragments } from '@/apollo/fragments/blog';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Blog } from '@/types/api';
import gql from 'graphql-tag';
import { BlogForm } from '.';
import { ChatForm } from '../chat/ChatForm';
import { LiveStreamForm } from '../liveStream/LiveStreamForm';
import { VideoForm } from '../video/VideoForm';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateBlog($input: CreateBlogInput!) {
        createBlog(input: $input) {
          blog {
            ...Blog
          }
        }
      }
      ${BlogFragments.Blog}
    `,
    refetchQueries: ['Blogs'],
  },
})
export class CreateBlogForm extends GraphQLForm<Blog> implements BlogForm {
  @Field()
  public title = '';
  @Field()
  public body = '';
  @Field()
  public image: File | null = null;
  @Field({ name: 'video' })
  public videoForm: VideoForm | null = null;
  @Field({ name: 'liveStream' })
  public liveStreamForm: LiveStreamForm | null = null;
  @Field({ name: 'chat' })
  public chatForm: ChatForm | null = null;

  public save() {
    return super.save({
      optimisticResponse: {
        createBlog: {
          blog: {
            id: 'optimistic-id',
            title: this.title,
            body: this.body,
            imageUrl: null,
            __typename: 'Blog',
            video: this.videoForm
              ? {
                  id: 'optimistic-video-id',
                  fileKey: this.videoForm.fileKey,
                  isCitizenRestricted: this.videoForm.isCitizenRestricted,
                  __typename: 'Video',
                }
              : null,
          },
          __typename: 'CreateBlogPayload',
        },
        __typename: 'Mutation',
      },
    });
  }
}
