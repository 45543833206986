import { QuestionFragments } from '@/apollo/fragments/question';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Question } from '@/types/api';
import gql from 'graphql-tag';
import { QuestionForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation Update($input: UpdateQuestionInput!) {
        updateQuestion(input: $input) {
          question {
            ...Question
          }
        }
      }
      ${QuestionFragments.Question}
    `,
  },
})
export class UpdateQuestionForm extends GraphQLForm<Question> implements QuestionForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public body: string;

  @Field()
  public sortOrder: number;

  constructor(question: Question) {
    super(question);
    this.id = question.id;
    this.title = question.title;
    this.body = question.body;
    this.sortOrder = question.sortOrder;
  }
}
