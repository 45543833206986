<template>
  <button class="button" :class="[{ 'button--danger': danger }]" v-on="$listeners">
    <slot />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseButton extends Vue {
  @Prop({
    default: false,
  })
  danger!: boolean;
}
</script>

<style lang="scss">
.button {
  border: 1px solid $color-theme-yellow;
  padding: 8px;
  background: none;
  color: $color-theme-yellow;
  transition: all 0.1s ease;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    color: $color-theme-red;
    background-color: $color-theme-yellow;
  }
  &:disabled {
    color: $color-disabled !important;
    border-color: $color-disabled !important;
    cursor: not-allowed;
  }
  &--danger {
    color: $color-theme-red;
    border-color: $color-theme-red;
  }
}
</style>
