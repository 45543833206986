import gql from 'graphql-tag';
import { ChatFragments } from './chat';
import { LiveStreamFragments } from './liveStream';
import { VideoFragments } from './video';

export namespace BlogFragments {
  export const Blog = gql`
    fragment Blog on Blog {
      id
      title
      body
      imageUrl
      sortOrder
      video {
        ...Video
      }
      liveStream {
        ...LiveStream
      }
      chat {
        ...Chat
      }
    }
    ${VideoFragments.Video}
    ${LiveStreamFragments.LiveStream}
    ${ChatFragments.Chat}
  `;
}
