import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { BorderControl } from '../../types/api';

export class BorderControlForm extends EmbeddedForm<BorderControl> {
  @Field()
  public earlyEntryLimit: number = 0;

  @Field()
  public details?: string | null;

  constructor(borderControl?: BorderControl) {
    super(borderControl);
    if (borderControl) {
      this.earlyEntryLimit = borderControl.earlyEntryLimit;
      this.details = borderControl.details;
    }
  }
}
