<template>
  <div class="modal">
    <div class="modal__overlay" @click="() => onClose()" />
    <div class="modal__content" :class="size">
      <header class="modal__header">
        <h2 class="modal__title">{{ title }}</h2>
        <slot name="header" />
      </header>
      <div class="modal__body">
        <slot />
        <div class="modal__body__inside">
          <slot name="body" />
        </div>
      </div>
      <footer class="modal__footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Modal',
})
export default class Modal extends Vue {
  @Prop({ required: true }) title!: string;

  @Prop({ default: 'md' }) size!: 'md' | 'lg';

  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  private onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    &.md {
      max-width: 600px;
    }
    &.lg {
      max-width: 800px;
    }
    background-color: #000;
    border-radius: 4px;
    overflow: hidden;
    max-height: 80vh;
    border: 1px solid $color-theme-red;
    box-shadow: 0 0 60px 20px #000;
    display: flex;
    flex-direction: column;
  }

  &__header {
    flex-shrink: 0;
    padding: 16px;
    border-bottom: 1px solid $color-theme-red;
  }

  &__title {
    margin: 0;
    font-size: 1.5rem;
  }

  &__body {
    flex: 1;
    overflow: auto;

    &__inside {
      width: 100%;
      height: 100%;
      padding: 16px;
    }
  }

  &__footer {
    flex-shrink: 0;
    padding: 16px;
    border-top: 1px solid $color-theme-red;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
