import gql from 'graphql-tag';

export namespace DocumentFragments {
  export const Document = gql`
    fragment Document on IDocument {
      id
      html
      createdAt
      ... on TermsDocument {
        revisions {
          id
          html
          createdAt
          acceptedCount
        }
      }
      ... on AboutDocument {
        revisions {
          id
          html
          createdAt
        }
      }
      ... on CookiesDocument {
        revisions {
          id
          html
          createdAt
        }
      }
      ... on MeetAndGreetTermsDocument {
        revisions {
          id
          html
          createdAt
        }
      }
    }
  `;
}
