import { QuestionFragments } from '@/apollo/fragments/question';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Question } from '@/types/api';
import gql from 'graphql-tag';
import { QuestionForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateQuestion($input: CreateQuestionInput!) {
        createQuestion(input: $input) {
          question {
            ...Question
          }
        }
      }
      ${QuestionFragments.Question}
    `,
    refetchQueries: ['Questions'],
  },
})
export class CreateQuestionForm extends GraphQLForm<Question> implements QuestionForm {
  @Field()
  public title = '';

  @Field()
  public body = '';

  public save() {
    return super.save({
      optimisticResponse: {
        createQuestion: {
          question: {
            id: 'optimistic-id',
            title: this.title,
            body: this.body,
            __typename: 'Question',
          },
          __typename: 'CreateQuestionPayload',
        },
        __typename: 'Mutation',
      },
    });
  }
}
