<template>
  <div class="questions">
    <base-button class="questions__add" v-if="!shouldAddQuestion" @click="shouldAddQuestion = true"
      >Add question</base-button
    >
    <transition-group name="list" mode="out-in" class="questions__list" tag="ul">
      <app-question-cell v-if="shouldAddQuestion" key="add" class="list__question" @close="shouldAddQuestion = false" />
      <app-question-cell v-for="question in questions" :key="question.id" :question="question" class="list__question" />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { Question } from '../types/api';
import AppQuestionCell from '@/components/QuestionCell.vue';
import { QuestionFragments } from '../apollo/fragments/question';

@Component({
  components: { AppQuestionCell },
  apollo: {
    questions: {
      query: gql`
        query Questions {
          questions {
            ...Question
          }
        }
        ${QuestionFragments.Question}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class Questions extends Vue {
  questions: Question[] = [];
  shouldAddQuestion = false;
}
</script>

<style lang="scss" scoped>
.questions {
  &__add {
    margin-bottom: 8px;
  }
}

.questions__list {
}

.list__question {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
