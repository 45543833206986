<template>
  <app-cell
    class="document-cell"
    :edit="!!form"
    :deletable="false"
    :loading="!!form && form.isLoading"
    @save="onSave"
    @cancel="onCancel"
  >
    <h2 class="document-cell__title">{{ title }}</h2>
    <template slot="content" v-if="document">
      <div class="document-cell__content">
        <p v-if="!revisions">
          <span>Last revision: {{ document.createdAt | dayjs('MMMM DD YYYY') }}</span>
          <a @click="revisions = true">– See all revisions</a>
        </p>
        <table v-else class="document-cell__revisions">
          <thead>
            <tr>
              <a @click="revisions = false">Hide revisions</a>
            </tr>
            <tr>
              <th>Created at</th>
              <th>Created by</th>
              <th v-if="document.__typename === 'TermsDocument'">Accepted by</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="revision in document.revisions"
              :key="revision.id"
              :class="{
                selected: selectedPreviewDocument && selectedPreviewDocument.id === revision.id,
              }"
            >
              <td>{{ revision.createdAt | dayjs('MMMM DD YYYY HH:mm') }}</td>
              <td>
                {{ revision.createdBy ? revision.createdBy.username : '–' }}
              </td>
              <td v-if="document.__typename === 'TermsDocument'">
                {{ revision.acceptedCount || 0 }}
              </td>
              <td>
                <a @click="selectedPreviewDocument = revision">Preview</a>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="!revisions || selectedPreviewDocument">
          <a v-if="!selectedPreviewDocument" @click="selectedPreviewDocument = document">Show preview</a>
          <a v-else @click="selectedPreviewDocument = null">Close preview</a>
        </p>
        <div v-if="selectedPreviewDocument" class="document-cell__preview" v-html="selectedPreviewDocument.html"></div>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="document-cell__form">
        <app-html-editor v-model="form.html" />
      </form>
    </template>
    <template slot="actions" v-if="!form">
      <base-button @click="newForm">Update</base-button>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import {
  DocumentForm,
  UpdateAboutForm,
  UpdateCookiesForm,
  UpdateMeetAndGreetTermsForm,
  UpdateTermsForm,
} from '@/forms/document';
import {
  isAboutDocument,
  isCookiesDocument,
  isMeetAndGreetTermsDocument,
  isTermsDocument,
} from '@/utils/assessInterfaceType';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IDocument } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor },
})
export default class DocumentCell extends Vue {
  @Prop({ required: true })
  public document!: IDocument;

  public selectedPreviewDocument: IDocument | null = null;

  public form = null as DocumentForm | null;

  public preview = false;
  public revisions = false;

  public get title(): string {
    if (isTermsDocument(this.document)) return 'Terms and conditions';
    if (isAboutDocument(this.document)) return 'About (sage)';
    if (isCookiesDocument(this.document)) return 'Cookie policy';
    if (isMeetAndGreetTermsDocument(this.document)) return 'Secret Gathering Terms and Conditions';
    else return 'Unknown document';
  }

  public onCancel() {
    this.closeForm();
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public newForm() {
    if (isTermsDocument(this.document)) this.form = new UpdateTermsForm(this.document);
    if (isAboutDocument(this.document)) this.form = new UpdateAboutForm(this.document);
    if (isCookiesDocument(this.document)) this.form = new UpdateCookiesForm(this.document);
    if (isMeetAndGreetTermsDocument(this.document)) this.form = new UpdateMeetAndGreetTermsForm(this.document);
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.document) {
      this.newForm();
    }
  }
}
</script>

<style lang="scss" scoped>
.document-cell {
  &__title {
    width: 100%;
  }
  &__content {
    width: 100%;
    p:not(:last-child) {
      margin: 8px 0;
      span {
        margin-right: 12px;
      }
    }
  }
  &__revisions {
    $margin: 12px;
    border-collapse: separate;
    border-spacing: $margin;
    margin: -$margin;
    tr.selected {
      color: $color-theme-red;
    }
    td {
      padding-right: 16px;
    }
  }
  &__preview {
    margin-top: 12px;
    padding: 24px;
    border: 1px solid $color-theme-yellow;
  }
  &__form {
    flex: 1;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    .form__title {
    }
  }
}
</style>
