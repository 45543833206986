<template>
  <div class="purchasables">
    <base-button class="purchasables__add" v-if="!shouldAddProduct" @click="shouldAddProduct = true"
      >Add product</base-button
    >
    <div class="purchasables__static">
      <base-button key="button" @click="showStaticPurchasables = !showStaticPurchasables">{{
        showStaticPurchasables ? 'Hide items' : 'Show static items'
      }}</base-button>
      <transition-group v-if="showStaticPurchasables" name="list" mode="out-in" class="purchasables__list" tag="ul">
        <app-citizenship-cell
          v-if="citizenship"
          :key="citizenship.id"
          :citizenship="citizenship"
          class="list__purchasable"
        />
        <app-purchasable-cell
          v-if="passport"
          :key="passport.id"
          type="static"
          :purchasable="passport"
          class="list__purchasable"
        />
        <app-purchasable-cell
          v-if="expressShipping"
          type="static"
          :key="expressShipping.id"
          :purchasable="expressShipping"
          class="list__purchasable"
        />
        <app-purchasable-cell
          v-if="photoPrint"
          type="static"
          :key="photoPrint.id"
          :purchasable="photoPrint"
          class="list__purchasable"
        />
      </transition-group>
    </div>
    <transition-group name="list" mode="out-in" class="purchasables__list" tag="ul">
      <app-purchasable-cell
        v-if="shouldAddProduct"
        key="add"
        type="product"
        class="list__purchasable"
        @close="shouldAddProduct = false"
      />
      <app-purchasable-cell
        v-for="product in products"
        :key="product.id"
        type="product"
        :purchasable="product"
        class="list__purchasable"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import AppCitizenshipCell from '@/components/CitizenshipCell.vue';
import AppPurchasableCell from '@/components/PurchasableCell.vue';
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { CitizenshipFragments } from '../apollo/fragments/citizenship';
import { PurchasableFragments } from '../apollo/fragments/purchasable';
import { Citizenship, ExpressShipping, Passport, PhotoPrint, Product } from '../types/api';

@Component({
  components: { AppPurchasableCell, AppCitizenshipCell },
  apollo: {
    products: {
      query: gql`
        query Products {
          products {
            ...Purchasable
          }
        }
        ${PurchasableFragments.Purchasable}
      `,
      fetchPolicy: 'cache-and-network',
    },
    citizenship: {
      query: gql`
        query Citizenship {
          citizenship {
            ...Citizenship
          }
        }
        ${CitizenshipFragments.Citizenship}
      `,
      fetchPolicy: 'cache-and-network',
    },
    passport: {
      query: gql`
        query Passport {
          passport {
            ...Purchasable
          }
        }
        ${PurchasableFragments.Purchasable}
      `,
      fetchPolicy: 'cache-and-network',
    },
    expressShipping: {
      query: gql`
        query ExpressShipping {
          expressShipping {
            ...Purchasable
          }
        }
        ${PurchasableFragments.Purchasable}
      `,
      fetchPolicy: 'cache-and-network',
    },
    photoPrint: {
      query: gql`
        query PhotoPrint {
          photoPrint {
            ...Purchasable
          }
        }
        ${PurchasableFragments.Purchasable}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class Store extends Vue {
  products: Product[] = [];
  passport: Passport | null = null;
  citizenship: Citizenship | null = null;
  expressShipping: ExpressShipping | null = null;
  photoPrint: PhotoPrint | null = null;
  shouldAddProduct = false;
  showStaticPurchasables = false;
}
</script>

<style lang="scss" scoped>
.purchasables {
  &__add {
    margin-bottom: 8px;
  }
}

.purchasables__static {
  margin-bottom: 16px;
  border: 1px solid $color-theme-red;
  padding: 16px;
  :not(:last-child) {
    margin-bottom: 8px;
  }
}

.purchasables__list {
}

.list__purchasable {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
