var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:['checkbox', { 'checkbox--disabled': _vm.disabled }],attrs:{"for":_vm.id}},[_vm._t("default"),_c('input',_vm._g(_vm._b({attrs:{"type":"checkbox"},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('update', $event.target.checked)}}},'input',
      _vm.$attrs
      // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    ,false),
      _vm.$listeners
      // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    )),_c('span',{staticClass:"checkbox__box"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.value && !_vm.disabled),expression:"value && !disabled"}],staticClass:"checkbox__checkmark"},[_vm._v("✔")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }