import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { errorLink } from './error';
import { httpLink } from './http';
import { wsLink } from './ws';

export const link = ApolloLink.from([
  errorLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  ),
]);
