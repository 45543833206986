import gql from 'graphql-tag';

export namespace VariantFragments {
  export const Variant = gql`
    fragment Variant on Variant {
      id
      name
      shopifyIdEU
      shopifyIdUS
      sortOrder
      outOfStockEU
      outOfStockUS
    }
  `;
}
