import { WebSocketLink } from 'apollo-link-ws';

// TODO: fix proxy

const isDev = window.location.host.includes('avatar-cms-dev');

const LOCAL_WEBSOCKET = 'ws://localhost:8080/subscriptions';
const DEV_WEBSOCKET = 'wss://avatar-server-dev.herokuapp.com/subscriptions';
const PROD_WEBSOCKET = 'wss://api.avatarcountry.com/subscriptions';

const uri = process.env.NODE_ENV === 'development' ? LOCAL_WEBSOCKET : isDev ? DEV_WEBSOCKET : PROD_WEBSOCKET;

export const wsLink = new WebSocketLink({
  uri,
  options: {
    reconnect: true,
    lazy: true,
    reconnectionAttempts: 50,
    timeout: 20000,
  },
});
