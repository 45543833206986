import gql from 'graphql-tag';

export namespace PostFragments {
  export const Post = gql`
    fragment Post on Post {
      id
      title
      html
      isNotification
      imageUrl
    }
  `;
}
