import gql from 'graphql-tag';

export namespace ChatFragments {
  export const Comment = gql`
    fragment Comment on ChatComment {
      id
      body
      createdAt
      userProfile {
        id
        username
        role
      }
    }
  `;

  export const ChatWithComments = gql`
    fragment ChatWithComments on Chat {
      id
      disabled
      comments(pagination: $pagination) {
        data {
          ...Comment
        }
        hasMore
      }
    }
    ${Comment}
  `;

  export const Chat = gql`
    fragment Chat on Chat {
      id
      disabled
    }
  `;
}
