import { Field } from '@/form/decorators/Field';
import { v4 as uuid } from 'uuid';
import { BaseVariantForm } from '.';
import EmbeddedForm from '../../../form/EmbeddedForm';
import { Variant } from '../../../types/api';

export class VariantForm extends EmbeddedForm<Variant> implements BaseVariantForm {
  key = uuid();

  @Field({ static: true })
  public id?: string;

  @Field()
  public name: string = '';

  @Field()
  public shopifyIdEU?: string | null = null;

  @Field()
  public shopifyIdUS?: string | null = null;

  @Field()
  public sortOrder = 0;

  @Field()
  public outOfStockEU: boolean = false;

  @Field()
  public outOfStockUS: boolean = false;

  constructor(variant?: Variant) {
    super(variant);
    if (variant) {
      this.id = variant.id;
      this.key = variant.id;
      this.name = variant.name;
      this.shopifyIdEU = variant.shopifyIdEU;
      this.shopifyIdUS = variant.shopifyIdUS;
      this.sortOrder = variant.sortOrder;
      this.outOfStockEU = variant.outOfStockEU;
      this.outOfStockUS = variant.outOfStockUS;
    }
  }
}
