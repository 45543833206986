import { DocumentFragments } from '@/apollo/fragments/document';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { CookiesDocument, IDocument } from '@/types/api';
import gql from 'graphql-tag';
import { DocumentForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdateCookies($input: UpdateCookiesInput!) {
        updateCookies(input: $input) {
          cookies {
            ...Document
          }
        }
      }
      ${DocumentFragments.Document}
    `,
    refetchQueries: ['Cookies'],
  },
})
export class UpdateCookiesForm extends GraphQLForm<IDocument> implements DocumentForm {
  @Field()
  public html: string;

  constructor(old: CookiesDocument) {
    super();
    this.html = old.html;
  }
}
