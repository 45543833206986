<template>
  <div class="movies">
    <base-button class="movies__add" v-if="!shouldAddMovie" @click="shouldAddMovie = true">Add movie</base-button>
    <transition-group name="list" mode="out-in" class="movies__list" tag="ul">
      <app-movie-cell v-if="shouldAddMovie" key="add" class="list__movie" @close="shouldAddMovie = false" />
      <app-movie-cell
        v-for="movie in movies"
        :key="movie.id"
        :movie="movie"
        class="list__movie"
        @watch="movieToWatch = movie"
      />
    </transition-group>
    <app-video-player
      v-if="movieToWatch"
      :streamUrl="movieToWatch.video.asset.streamUrl"
      class="player"
      @close="movieToWatch = null"
    />
  </div>
</template>

<script lang="ts">
import AppMovieCell from '@/components/MovieCell.vue';
import AppVideoPlayer from '@/components/VideoPlayer.vue';
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { MovieFragments } from '../apollo/fragments/movie';
import { Movie } from '../types/api';

@Component({
  components: { AppMovieCell, AppVideoPlayer },
  apollo: {
    movies: {
      query: gql`
        query Movies {
          movies {
            ...Movie
          }
        }
        ${MovieFragments.Movie}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class Cinema extends Vue {
  movies: Movie[] = [];
  movieToWatch: Movie | null = null;
  shouldAddMovie = false;
}
</script>

<style lang="scss" scoped>
.movies {
  &__add {
    margin-bottom: 8px;
  }
}

.movies__list {
}

.list__movie {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
