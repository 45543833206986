<template>
  <app-cell class="stats" :editable="false" :deletable="false">
    <h3>Users Stats</h3>

    <div class="boxes">
      <section class="box">
        <h4>Total users: {{ totalUsersCount }}</h4>
        <h4>Total citizens: {{ totalCitizensCount }}</h4>
      </section>

      <div class="filter">
        <base-form-input label="From">
          <base-date-input v-model="from" />
        </base-form-input>
        <base-form-input label="To">
          <base-date-input v-model="to" />
        </base-form-input>
      </div>

      <section class="box">
        <h4>Registered users: {{ registeredUsersCount }}</h4>
        <h4>Active users: {{ activeUsersCount }}</h4>
      </section>
    </div>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { SmartQuery } from 'vue-apollo/types/vue-apollo';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: { AppCell },
  apollo: {
    totalUsersCount: {
      query: gql`
        {
          totalUsersCount
        }
      `,
      fetchPolicy: 'cache-and-network',
    },
    totalCitizensCount: {
      query: gql`
        {
          totalCitizensCount
        }
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class UsersStats extends Vue {
  totalUsersCount: number | null = null;
  totalCitizensCount: number | null = null;
  from = dayjs().subtract(1, 'M').toDate();
  to = new Date();
  registeredUsersCount: number | null = null;
  $registeredUsersCount: SmartQuery<number> | null = null;
  activeUsersCount: number | null = null;
  $activeUsersCount: SmartQuery<number> | null = null;

  @Watch('from')
  @Watch('to', { immediate: true })
  datesChanged() {
    this.$registeredUsersCount = this.$apollo.addSmartQuery('registeredUsersCount', {
      query: gql`
        query RegisteredUsersCount($from: DateTime!, $to: DateTime!) {
          registeredUsersCount(from: $from, to: $to)
        }
      `,
      variables: {
        from: this.from,
        to: this.to,
      },
    });

    this.$activeUsersCount = this.$apollo.addSmartQuery('activeUsersCount', {
      query: gql`
        query ActiveUsersCount($from: DateTime!, $to: DateTime!) {
          activeUsersCount(from: $from, to: $to)
        }
      `,
      variables: {
        from: this.from,
        to: this.to,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.stats {
  .boxes {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .filter {
    display: flex;
    gap: 12px;
  }

  .box {
    padding: 8px 12px;
    border: 1px solid $color-theme-yellow;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
