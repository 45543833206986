import {
  AboutDocument,
  CookiesDocument,
  Deposit,
  IDocument,
  IPurchasable,
  ITransaction,
  MeetAndGreetProduct,
  MeetAndGreetTermsDocument,
  MeetAndGreetTicketPurchase,
  Order,
  Product,
  TermsDocument,
  Ticket,
} from '@/types/api';

export const isTermsDocument = (object: IDocument): object is TermsDocument => {
  return (object as any).__typename === 'TermsDocument';
};

export const isAboutDocument = (object: IDocument): object is AboutDocument => {
  return (object as any).__typename === 'AboutDocument';
};

export const isCookiesDocument = (object: IDocument): object is CookiesDocument => {
  return (object as any).__typename === 'CookiesDocument';
};

export const isMeetAndGreetTermsDocument = (object: IDocument): object is MeetAndGreetTermsDocument => {
  return (object as any).__typename === 'MeetAndGreetTermsDocument';
};

export const isProduct = (object: IPurchasable): object is Product => {
  return (object as any).__typename === 'Product';
};

export const isMeetAndGreetProduct = (object: IPurchasable): object is MeetAndGreetProduct => {
  return (object as any).__typename === 'MeetAndGreetProduct';
};

export const isDeposit = (object: ITransaction): object is Deposit => {
  return (object as any).__typename === 'Deposit';
};

export const isTicket = (object: ITransaction): object is Ticket => {
  return (object as any).__typename === 'Ticket';
};

export const isMeetAndGreetTicketPurchase = (object: ITransaction): object is MeetAndGreetTicketPurchase => {
  return (object as any).__typename === 'MeetAndGreetTicketPurchase';
};

export const isOrder = (object: ITransaction): object is Order => {
  return (object as any).__typename === 'Order';
};
