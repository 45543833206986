<template>
  <app-cell
    class="subtitle-cell"
    :edit="!!form"
    :editable="false"
    :deletable="!!subtitle"
    :loading="!!form && form.isLoading"
    @save="onSave"
    @cancel="closeForm"
    @delete="onDelete"
  >
    <template slot="content" v-if="subtitle">
      <div class="subtitle-cell__content">
        <h3 class="subtitle-cell__title">
          {{ subtitle.name }}
          <strong>[{{ subtitle.languageCode }}]</strong>
          <strong class="status">({{ subtitle.status }})</strong>
        </h3>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="subtitle-cell__form">
        <ul class="form__errors" v-if="form.errorMessages">
          <li v-for="error in form.errorMessages" class="error" :key="error">
            {{ error }}
          </li>
        </ul>
        <base-form-input class="form__input" label="Subtitle name">
          <input v-model="form.name" placeholder="English" />
        </base-form-input>
        <base-form-input class="form__input" label="File">
          <input @change="onFileUpload" placeholder="File SRT" type="file" accept=".srt,.vtt" />
        </base-form-input>
        <base-form-input class="form__input" label="Language code">
          <input v-model="form.languageCode" placeholder="en-US" />
          <span slot="hint"
            >BCP 47 specification compliant value. For example, en for English or en-US for the US version of
            English.</span
          >
        </base-form-input>
      </form>
    </template>
    <template slot="actions" v-if="!form">
      <base-button @click="onDelete" :danger="true">Delete</base-button>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import { AddSubtitleForm } from '@/forms/subtitle/AddSubtitleForm';
import { DeleteSubtitleTrackInput, MuxSubtitleTrack } from '@/types/api';
import { getFileFromUploadEvent } from '@/utils/getFileFromUploadEvent';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { AppCell, AppHtmlEditor },
})
export default class SubtitleCell extends Vue {
  @Prop({ required: true })
  assetId!: string;

  @Prop()
  subtitle?: MuxSubtitleTrack;

  public form = null as AddSubtitleForm | null;

  public onFileUpload(e: HTMLFileUploadEvent) {
    const file = getFileFromUploadEvent(e);
    this.form!.file = file;
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public async onDelete() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteSubtitleTrack($input: DeleteSubtitleTrackInput!) {
          deleteSubtitleTrack(input: $input) {
            success
          }
        }
      `,
      variables: { input: { trackId: this.subtitle!.id, assetId: this.assetId } satisfies DeleteSubtitleTrackInput },
      refetchQueries: ['Movies'],
    });
  }

  public closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.subtitle) {
      this.form = new AddSubtitleForm(this.assetId);
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle-cell {
  &__content {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 1rem;
    margin: unset;

    .status {
      margin-left: 4px;
      text-transform: uppercase;
      color: $color-theme-yellow;
    }
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .form__description {
      margin-bottom: 12px;
      i {
        font-style: italic;
      }
    }
    .form__errors {
      font-weight: bold;
      color: $color-theme-red;
      margin-bottom: 8px;
      line-height: 2em;
    }
  }
}
</style>
