<template>
  <modal class="modal" title="Users" size="lg" @close="() => onClose()">
    <template slot="header" v-if="show">
      <base-form-input label="Filter" class="modal__filter">
        <select v-model="filter" class="modal__input">
          <option value="applications">Applications ({{ applicationsCount }})</option>
          <option value="Loyal">Loyal ({{ show.meetAndGreet?.numberOfLoyalTickets }})</option>
          <option value="Purchase">Purchased ({{ show.meetAndGreet?.numberOfTicketsPurchased }})</option>
          <option value="Random">Random ({{ show.meetAndGreet?.numberOfRandomTickets }})</option>
        </select>
      </base-form-input>
    </template>
    <template slot="body" v-if="show">
      <table v-if="filter === 'applications'">
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="application in sortedApplicants" :key="application.id" :class="{ leading: application.isLeading }">
            <td>
              <router-link :to="{ path: '/users', query: { q: application.user.id } }">
                {{ application.user.username }}
              </router-link>
            </td>
            <td>{{ application.user.email }}</td>
            <td>{{ application.user.score?.total }}</td>
          </tr>
        </tbody>
      </table>
      <table v-else>
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Score</th>
            <th>Quota</th>
            <th>Quantity</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in ticketsWithStatus" :key="ticket.id">
            <td>
              <router-link :to="{ path: '/users', query: { q: ticket.user?.id } }">
                {{ ticket.user?.username }}
              </router-link>
            </td>
            <td>{{ ticket.user?.email }}</td>
            <td>{{ ticket.user?.score?.total }}</td>
            <td>{{ ticket.quota }}</td>
            <td>
              {{
                filter === 'Purchase'
                  ? ticket.purchases.filter((p) => !p.refundedAt).reduce((sum, p) => sum + p.quantity, 0)
                  : 1
              }}
            </td>
            <td>{{ ticket.status }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <template slot="footer">
      <base-button class="modal__button" @click="onClose">Close</base-button>
    </template>
  </modal>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MeetAndGreetQuota, Show } from '../types/api';
import Modal from './Modal.vue';

@Component({
  name: 'MeetAndGreetModal',
  components: {
    Modal,
  },
  apollo: {
    show: {
      query: gql`
        query MeetAndGreetUsers($showId: ID!) {
          show(id: $showId) {
            id
            meetAndGreet {
              numberOfTicketsPurchased
              numberOfRandomTickets
              numberOfLoyalTickets

              tickets {
                id
                quota
                confirmedAt
                declinedAt
                purchases {
                  id
                  refundedAt
                  quantity
                }
                user {
                  id
                  email
                  username
                  score {
                    total
                  }
                }
              }
              applications {
                id
                user {
                  id
                  email
                  username
                  score {
                    total
                  }
                }
              }

              leadingApplicants {
                id
              }
            }
          }
        }
      `,
      variables() {
        return {
          showId: this.showId,
        };
      },
    },
  },
})
export default class MeetAndGreetModal extends Vue {
  @Prop({ required: true }) showId!: string;
  @Prop({ required: true }) defaultFilter!: 'applications' | MeetAndGreetQuota;

  show?: Show;

  filter = this.defaultFilter;

  onClose() {
    this.$emit('close');
  }

  get sortedApplicants() {
    return (
      this.show?.meetAndGreet?.applications
        .sort((a, b) => {
          return (b.user.score?.total || 0) - (a.user.score?.total || 0);
        })
        .map((application) => ({
          ...application,
          isLeading: this.show?.meetAndGreet?.leadingApplicants.some((a) => a.id === application.id),
        })) || []
    );
  }

  get tickets() {
    switch (this.filter) {
      case MeetAndGreetQuota.Purchase:
        return this.show?.meetAndGreet?.tickets.filter((ticket) => ticket.purchases.length) || [];
      case 'applications':
        return [];
      default:
        return this.show?.meetAndGreet?.tickets.filter((ticket) => ticket.quota === this.filter) || [];
    }
  }

  get ticketsWithStatus() {
    return this.tickets.map((ticket) => {
      const refundedTickets = ticket.purchases.filter((p) => p.refundedAt).reduce((sum, p) => sum + p.quantity, 0);
      return {
        ...ticket,
        status:
          refundedTickets > 0
            ? `${refundedTickets} refunded`
            : ticket.confirmedAt
            ? 'Confirmed'
            : ticket.declinedAt
            ? 'Declined'
            : 'Pending',
      };
    });
  }

  get applicationsCount() {
    return this.show?.meetAndGreet?.applications.length || 0;
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__button {
    margin-left: 8px;
  }

  &__filter {
    margin-top: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid $color-theme-red;
      padding: 8px;
    }

    th {
      background-color: #111;
    }

    tr:nth-child(even) {
      background-color: #111;
    }

    tr.leading {
      background-color: #2a0c08;
    }

    tr.leading:nth-child(even) {
      background-color: #3a0c08;
    }
  }
}
</style>
