import gql from 'graphql-tag';

export namespace QuestionFragments {
  export const Question = gql`
    fragment Question on Question {
      id
      title
      body
      sortOrder
    }
  `;
}
