import { PurchasableFragments } from '@/apollo/fragments/purchasable';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { IPurchasable } from '@/types/api';
import gql from 'graphql-tag';
import { PurchasableForm } from '.';
import { VariantForm } from './variant/VariantForm';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdatePurchasable($input: UpdatePurchasableInput!) {
        updatePurchasable(input: $input) {
          purchasable {
            ...Purchasable
          }
        }
      }
      ${PurchasableFragments.Purchasable}
    `,
  },
})
export class UpdatePurchasableForm extends GraphQLForm<IPurchasable> implements PurchasableForm<IPurchasable> {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public description: string;

  @Field()
  public price: number;

  @Field()
  public image: File | null = null;

  @Field()
  public variantInputs: VariantForm[];

  constructor(purchasable: IPurchasable) {
    super(purchasable);
    this.id = purchasable.id;
    this.title = purchasable.title;
    this.description = purchasable.description;
    this.price = purchasable.price;
    this.variantInputs = purchasable.variants.map((a) => new VariantForm(a));
  }
}
