<template>
  <label class="base-form-input">
    <div class="header">
      <span class="label">{{ label }}</span>
      <span class="detail" v-if="optional">{{ 'Optional' }}</span>
    </div>
    <slot></slot>
    <p class="hint">
      <slot name="hint"></slot>
    </p>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseFormInput extends Vue {
  @Prop() label!: string;
  @Prop({ default: false }) optional!: boolean;
}
</script>

<style lang="scss" scoped>
.base-form-input {
  display: flex;
  flex-direction: column;
  .header {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    .label {
      flex: 1;
      color: $color-theme-red;
    }
  }

  .hint {
    font-size: 0.8em;
    color: $color-theme-red;
  }
}
</style>
