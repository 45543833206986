import { persistCache } from 'apollo-cache-persist';
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { apolloClient } from './client';

Vue.use(VueApollo);

class NormalizedCacheObject {}

export default async function () {
  try {
    await persistCache({
      cache: apolloClient.cache,
      storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
    });
  } catch (e: any) {
    console.error('Failed to setup persisting cache:', e);
  }
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}
