import { apolloClient } from '@/apollo/client';
import gql from 'graphql-tag';
import Vue from 'vue';
import { NavigationGuard } from 'vue-router';
import { UserRole } from '../types/api';

const checkIfUserIsAuthorized = async (): Promise<boolean> => {
  const { data } = await apolloClient.query({
    query: gql`
      {
        viewer {
          id
          role
        }
      }
    `,
    fetchPolicy: 'no-cache',
  });
  return (data && data.viewer && data.viewer.role === UserRole.Admin) || false;
};

export const adminGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const isAuthorized = await checkIfUserIsAuthorized();
  if (isAuthorized) {
    next();
  } else {
    next('/login');
  }
};

export const loginGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const isAuthorized = await checkIfUserIsAuthorized();
  if (isAuthorized) {
    next('/');
  } else {
    next();
  }
};
