<template>
  <div class="admin">
    <div class="admin__sidebar">
      <router-link to="dashboard">
        <img class="logo" alt="Avatar country logo" src="@/assets/logo.png" />
      </router-link>
      <nav class="nav">
        <router-link class="nav__item" to="/news">News</router-link>
        <router-link class="nav__item" to="/questions">FAQ</router-link>
        <router-link class="nav__item" to="/cinema">Cinema</router-link>
        <router-link class="nav__item" to="/studio">Studio</router-link>
        <router-link class="nav__item" to="/store">Store</router-link>
        <router-link class="nav__item" to="/shows">Shows</router-link>
        <router-link class="nav__item" to="/docs">Docs</router-link>
        <router-link class="nav__item" to="/users">Users</router-link>
        <div class="nav__item nav__actions">
          <base-button @click="onLogout">Logout</base-button>
        </div>
      </nav>
    </div>
    <div class="admin__view">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Admin extends Vue {
  public async onLogout() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation Logout {
          logout {
            success
          }
        }
      `,
    });
    this.$router.push('/login').catch(() => {});
  }
}
</script>

<style lang="scss" scoped>
.admin {
  display: flex;
  gap: 32px;
  height: 100%;
  overflow: hidden;

  @include mobile {
    flex-direction: column;
    height: unset;
  }
}

.admin__sidebar {
  flex: 1 1 120px;
  padding: 16px;

  color: $color-theme-yellow;
  display: flex;
  flex-direction: column;

  .logo {
    max-width: 200px;
  }

  .nav {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .nav__item {
      padding: 20px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $color-theme-yellow;
        &.router-link-exact-active {
          border-color: $color-theme-red;
        }
      }
    }

    a.nav__item {
      color: $color-theme-yellow;
      text-decoration: none;
      // font-weight: bold;
      &.router-link-exact-active {
        color: $color-theme-red;
      }
    }

    .nav__actions {
      display: flex;
      flex-direction: column;
    }
  }
}

.admin__view {
  padding: 16px;
  flex: 8;
  overflow: auto;
}
</style>
