import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { Chat } from '../../types/api';

export class ChatForm extends EmbeddedForm<Chat> {
  @Field()
  public disabled: boolean = false;

  constructor(chat?: Chat) {
    super(chat);
    if (chat) {
      this.disabled = chat.disabled;
    }
  }
}
