import ApolloClient from 'apollo-client';
import { cache } from './cache';
import { link } from './link';
// import { stateLink } from './link/state';
// import { resolvers } from './resolvers';

export const apolloClient = new ApolloClient({
  link,
  cache,
  // resolvers,
});

apolloClient.onResetStore(async () => {
  // stateLink.writeDefaults();
});
// apolloClient.onClearStore(async () => {
//   stateLink.writeDefaults();
// });
// apolloClient.wsClient = wsClient;
