<template>
  <div class="shows">
    <div class="products__static">
      <base-button key="button" @click="showProducts = !showProducts">{{
        showProducts ? 'Hide products' : 'Show Secret Gathering products'
      }}</base-button>

      <base-button v-if="showProducts && !shouldAddProduct" class="products__add" @click="shouldAddProduct = true"
        >Add product</base-button
      >
      <transition-group v-if="showProducts" name="list" mode="out-in" class="products__list" tag="ul">
        <app-purchasable-cell
          v-if="shouldAddProduct"
          key="add"
          type="meetAndGreet"
          class="list__purchasable"
          @close="shouldAddProduct = false"
        />
        <app-purchasable-cell
          v-for="product in meetAndGreetProducts"
          :key="product.id"
          type="meetAndGreet"
          :purchasable="product"
          class="list__purchasable"
        />
      </transition-group>
    </div>

    <base-button class="shows__add" @click="onAddShow">Add show</base-button>
    <base-button class="shows__add" @click="onImport" :disabled="loadingImport">Import from bandsintown</base-button>
    <base-button class="shows__add" @click="onTogglePastShows">{{
      showPastShows ? 'Hide past shows' : 'Show past shows'
    }}</base-button>

    <transition-group name="list" mode="out-in" class="shows__list" tag="ul">
      <app-show-cell
        v-for="form in forms"
        :key="form.id"
        :data="form.data"
        class="list__show"
        @close="onCloseForm(form)"
      />
      <template v-if="showPastShows">
        <app-show-cell
          v-for="show in pastShows"
          :key="show.id"
          :show="show"
          class="list__show"
          :focused="focusShow?.id === show.id"
        />
      </template>
      <app-show-cell
        v-for="show in futureShows"
        :key="show.id"
        :show="show"
        class="list__show"
        :focused="focusShow?.id === show.id"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import AppPurchasableCell from '@/components/PurchasableCell.vue';
import AppShowCell from '@/components/ShowCell.vue';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { PurchasableFragments } from '../apollo/fragments/purchasable';
import { ShowFragments } from '../apollo/fragments/show';
import { ImportedShow, MeetAndGreetProduct, Show } from '../types/api';

type FormContext = { id: string; data?: Omit<ImportedShow, 'bitId'> };

@Component({
  components: { AppShowCell, AppPurchasableCell },
  apollo: {
    shows: {
      query: gql`
        query Shows {
          shows {
            ...Show
          }
        }
        ${ShowFragments.Show}
      `,
      fetchPolicy: 'cache-and-network',
    },
    meetAndGreetProducts: {
      query: gql`
        query MeetAndGreetProducts {
          meetAndGreetProducts {
            ...Purchasable
          }
        }
        ${PurchasableFragments.Purchasable}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class Shows extends Vue {
  shows: Show[] = [];
  meetAndGreetProducts?: MeetAndGreetProduct[];
  forms: FormContext[] = [];
  loadingImport = false;
  showPastShows = false;
  showProducts = false;
  shouldAddProduct = false;

  public get focusShow() {
    return this.shows.find((show) => show.id === this.$route.hash.slice(1));
  }

  public get pastShows() {
    return this.shows.filter((show) => dayjs().isAfter(show.showDate));
  }

  public get futureShows() {
    return this.shows.filter((show) => !dayjs().isAfter(show.showDate));
  }

  public onAddShow() {
    this.forms.push({ id: Date.now().toString() });
  }

  public onTogglePastShows() {
    this.showPastShows = !this.showPastShows;
  }

  public async onImport() {
    this.loadingImport = true;
    try {
      const { data } = await this.$apollo.query<{
        importShows: ImportedShow[];
      }>({
        query: gql`
          {
            importShows {
              bitId
              description
              externalTicketLink
              showDate
              address {
                venue
                city
                countryId
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
      });
      const shows = data.importShows;
      this.forms.push(
        ...shows.map(({ bitId, ...formData }) => ({
          id: bitId,
          data: formData,
        }))
      );
    } finally {
      this.loadingImport = false;
    }
  }

  public onCloseForm(form: FormContext) {
    this.forms = this.forms.filter((a) => a.id !== form.id);
  }

  @Watch('focusShow', { immediate: true })
  public onShowFocusChange() {
    const focusShow = this.focusShow;
    if (focusShow && this.pastShows.some((past) => past.id === focusShow.id)) {
      this.showPastShows = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.shows {
  &__add {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.shows__list {
}

.products__add {
  margin-left: 8px;
}

.products__static {
  margin-bottom: 16px;
  border: 1px solid $color-theme-red;
  padding: 16px;
  & > ul {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.products__list {
}

.list__show {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
