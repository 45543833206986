import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Citizenship } from '@/types/api';
import gql from 'graphql-tag';
import { CitizenshipFragments } from '../../apollo/fragments/citizenship';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdateCitizenship($input: UpdateCitizenshipInput!) {
        updateCitizenship(input: $input) {
          citizenship {
            ...Citizenship
          }
        }
      }
      ${CitizenshipFragments.Citizenship}
    `,
  },
})
export class UpdateCitizenshipForm extends GraphQLForm<Citizenship> {
  @Field()
  public title: string;

  @Field()
  public description: string;

  @Field()
  public image: File | null = null;

  @Field()
  public monthlyPrice: number;

  @Field()
  public yearlyPrice: number;

  constructor(citizenship: Citizenship) {
    super(citizenship);
    this.title = citizenship.title;
    this.description = citizenship.description;
    this.monthlyPrice = citizenship.monthlyPrice;
    this.yearlyPrice = citizenship.yearlyPrice;
  }
}
