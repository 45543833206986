import gql from 'graphql-tag';
import { MuxAssetFragments } from './muxAsset';

export namespace VideoFragments {
  export const Video = gql`
    fragment Video on Video {
      id
      url
      fileKey
      isCitizenRestricted
      asset {
        ...MuxAsset
      }
    }
    ${MuxAssetFragments.MuxAsset}
  `;
}
